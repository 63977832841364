

import * as tx from './strings';


// Defined values

// Global filtesr
export const FV_KEY_AGGREGATIONS = 'aggregations';
export const FV_KEY_SORT = 'sort';


// Order filters (table.js)
export const FV_KEY_ORDER_NUMBER = 'display_number';
export const FV_KEY_ORDER_CUSTOMER_NAME = 'customer_name';
export const FV_KEY_ORDER_CUSTOMER_EMAIL = 'customer_email';
export const FV_KEY_ORDER_PRODUCT_NAME = 'product_name';
export const FV_KEY_ORDER_DATE_MIN = 'order_date_min';
export const FV_KEY_ORDER_DATE_MAX = 'order_date_max';
export const FV_KEY_ORDER_STATUS = 'order_status';
export const FV_KEY_ORDER_PAYMENT_METHOD = 'payment_method';
export const FV_KEY_ORDER_SHIPPING_METHOD = 'shipping_method';


// Lorcana filters
export const FV_KEY_LORCANA_SET = 'lorcana_set';


// Magic filters
export const FV_KEY_MAGIC_COLOR = 'magic_color';
export const FV_KEY_MAGIC_RARITY = 'magic_rarity';
export const FV_KEY_MAGIC_SET = 'magic_set';


// Pokemon filters
export const FV_KEY_POKEMON_SET = 'pokemon_set';


// Star Wars filters
export const FV_KEY_STARWARS_SET = 'starwars_set';


export const FV_KEY_BUY_PRICE_MIN = 'buy_price_min';
export const FV_KEY_BUY_PRICE_MAX = 'buy_price_max';
export const FV_KEY_CONDITION = 'condition';
export const FV_KEY_DISPLAY_MIN = 'display_min';
export const FV_KEY_FINISH = 'finish';
export const FV_KEY_IN_STOCK = 'in_stock';
export const FV_KEY_IS_SEALED = 'is_sealed';
export const FV_KEY_IS_BUYLIST = 'is_buylist';
export const FV_KEY_LANGUAGE = 'language';
export const FV_KEY_NAME = 'name';
export const FV_KEY_PRICE = 'price';
export const FV_KEY_PRINTING = 'printing';
export const FV_KEY_PRODUCT_LINE = 'product_line';
export const FV_KEY_SELL_PRICE_MIN = 'sell_price_min';
export const FV_KEY_SELL_PRICE_MAX = 'sell_price_max';
export const FV_KEY_TOTAL_QUANTITY_MIN = 'total_quantity_min';
export const FV_KEY_TOTAL_QUANTITY_MAX = 'total_quantity_max';


export const FV_TYPE_CHECKBOXES = 'FV_TYPE_CHECKBOXES';
export const FV_TYPE_DROPDOWN = 'FV_TYPE_DROPDOWN';
export const FV_TYPE_LORCANA_SET = 'FV_TYPE_LORCANA_SET';
export const FV_TYPE_MAGIC_SET = 'FV_TYPE_MAGIC_SET';
export const FV_TYPE_POKEMON_SET = 'FV_TYPE_POKEMON_SET';
export const FV_TYPE_RANGE_INPUT = 'FV_TYPE_RANGE_INPUT';
export const FV_TYPE_STARWARS_SET = 'FV_TYPE_STARWARS_SET';
export const FV_TYPE_TEXT_INPUT = 'FV_TYPE_TEXT_INPUT';

export const TV_VALUE_DISPLAY_TYPE_PRICE = 'price';




// Filters

export const FILTER_PRICE = {
	key: FV_KEY_PRICE,
	label: tx.TX_PRICE,	
	type: FV_TYPE_RANGE_INPUT, 

	displayType: TV_VALUE_DISPLAY_TYPE_PRICE,
	noTranslate: true,

	keyMin: FV_KEY_SELL_PRICE_MIN,
	keyMax: FV_KEY_SELL_PRICE_MAX,

	labelMin: tx.TX_PRICE_MIN,
	labelMax: tx.TX_PRICE_MAX,

	altConfig: {
		isBuylist: {
			keyMin: FV_KEY_BUY_PRICE_MIN,
			keyMax: FV_KEY_BUY_PRICE_MAX,
		},
	},
};

export const FILTER_PRODUCT_NAME = {
	key: FV_KEY_NAME,
	label: tx.TX_NAME,	
	type: FV_TYPE_TEXT_INPUT, 

	noTranslate: true,
};

export const FILTER_PRODUCT_LINE = {
	key: FV_KEY_PRODUCT_LINE,
	label: tx.TX_PRODUCT_LINE,	
	type: FV_TYPE_DROPDOWN, 

	noTranslate: true,
	placeholder: tx.TX_FILTER_PRODUCT_LINE_ALL,
};

export const FILTER_AVAILABILITY = {
	key: FV_KEY_IN_STOCK,
	label: tx.TX_FILTER_AVAILABILITY,	
	type: FV_TYPE_CHECKBOXES, 
	aggregationKey: FV_KEY_IN_STOCK,
	canMinimize: true,

	options: [
		{
			display: tx.TX_FILTER_SHOW_OUT_OF_STOCK,
			value: false,
			count: null,
		},
	],
}

export const FILTER_PRODUCT_TYPE = {
	key: FV_KEY_IS_SEALED,
	label: tx.TX_FILTER_PRODUCT_TYPE,	
	type: FV_TYPE_CHECKBOXES, 
	aggregationKey: FV_KEY_IS_SEALED,
	canMinimize: true,

	options: [
		{
			display: tx.TX_FILTER_SEALED,
			value: true,
			count: null,
		},
		{
			display: tx.TX_FILTER_SINGLE,
			value: false,
			count: null,
		},
	],
};

export const FILTER_CONDITION = {
	key: FV_KEY_CONDITION,
	label: tx.TX_CONDITION,	
	type: FV_TYPE_CHECKBOXES, 
	aggregationKey: FV_KEY_CONDITION,
	canMinimize: true,
};

export const FILTER_LANGUAGE = {
	key: FV_KEY_LANGUAGE,
	label: tx.TX_LANGUAGE,	
	type: FV_TYPE_CHECKBOXES, 
	aggregationKey: FV_KEY_LANGUAGE,
	canMinimize: true,
};

export const FILTER_FINISH = {
	key: FV_KEY_FINISH,
	label: tx.TX_FILTER_FINISH,	
	type: FV_TYPE_CHECKBOXES, 
	aggregationKey: FV_KEY_FINISH,
	canMinimize: true,
};

export const FILTER_MAGIC_SET = {
	key: FV_KEY_MAGIC_SET,
	label: tx.TX_SET,	
	type: FV_TYPE_MAGIC_SET, 
	aggregationKey: FV_KEY_MAGIC_SET,
	dynamicOptions: true,
	canMinimize: true,
	noTranslate: true,
}

export const FILTER_POKEMON_SET = {
	key: FV_KEY_POKEMON_SET,
	label: tx.TX_SET,	
	type: FV_TYPE_POKEMON_SET, 
	aggregationKey: FV_KEY_POKEMON_SET,
	dynamicOptions: true,
	canMinimize: true,
	noTranslate: true,
}

export const FILTER_LORCANA_SET = {
	key: FV_KEY_LORCANA_SET,
	label: tx.TX_SET,	
	type: FV_TYPE_LORCANA_SET, 
	aggregationKey: FV_KEY_LORCANA_SET,
	dynamicOptions: true,
	canMinimize: true,
	noTranslate: true,
}

export const FILTER_STARWARS_SET = {
	key: FV_KEY_STARWARS_SET,
	label: tx.TX_SET,	
	type: FV_TYPE_STARWARS_SET, 
	aggregationKey: FV_KEY_STARWARS_SET,
	dynamicOptions: true,
	canMinimize: true,
	noTranslate: true,
}



// Filter Configurations

// Used programatically; all options need to be in this array
export const FILTERS_ALL = [
	FILTER_PRODUCT_NAME, 
	FILTER_CONDITION, 
	FILTER_PRICE, 
	FILTER_PRODUCT_LINE, 
	FILTER_PRODUCT_TYPE, 
	FILTER_LANGUAGE, 
	FILTER_FINISH, 
	FILTER_AVAILABILITY, 
	FILTER_LORCANA_SET, 
	FILTER_MAGIC_SET, 
	FILTER_POKEMON_SET, 
];


export const FILTERS_GENERAL = [
	FILTER_PRODUCT_LINE, 
	FILTER_PRICE,
	FILTER_AVAILABILITY,
];

export const FILTERS_GENERAL_OVERRIDE = {
	pixelatoms: [
		FILTER_PRODUCT_LINE, 
		FILTER_PRICE,
		Object.assign({}, FILTER_AVAILABILITY, {
			initSelection: [ 'false' ],
		}),
	],
};

export const FILTERS_MAGIC = [
	FILTER_PRODUCT_LINE, 
	FILTER_PRICE,
	FILTER_PRODUCT_TYPE, 
	FILTER_CONDITION, 
	FILTER_FINISH, 
	FILTER_MAGIC_SET,
	FILTER_LANGUAGE, 
	FILTER_AVAILABILITY, 
];

export const FILTERS_POKEMON = [
	FILTER_PRODUCT_LINE, 
	FILTER_PRICE,
	FILTER_CONDITION, 
	FILTER_FINISH, 
	FILTER_PRODUCT_TYPE, 
	FILTER_POKEMON_SET, 
	FILTER_LANGUAGE, 
	FILTER_AVAILABILITY, 
];

export const FILTERS_LORCANA = [
	FILTER_PRODUCT_LINE, 
	FILTER_PRICE,
	FILTER_CONDITION, 
	FILTER_FINISH, 
	FILTER_PRODUCT_TYPE, 
	FILTER_LORCANA_SET, 
	FILTER_LANGUAGE, 
	FILTER_AVAILABILITY, 
];

export const FILTERS_STARWARS = [
	FILTER_PRODUCT_LINE, 
	FILTER_PRICE,
	FILTER_CONDITION, 
	FILTER_FINISH, 
	FILTER_PRODUCT_TYPE, 
	FILTER_STARWARS_SET, 
	FILTER_LANGUAGE, 
	FILTER_AVAILABILITY, 
];










