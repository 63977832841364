import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { history } from '../../store';

import { 
  CONDITIONS_PRODUCT_GENERIC, 
  CONDITIONS_PRODUCT_TCG, 
} from '../../constants/conditions';
import { 
  CSS_HEADER_HEIGHT,
  CSS_HEADER_NOTICE_HEIGHT, 
  CSS_SLIDE_DURATION, 
} from '../../constants/css';
import { 
  FILTERS_LORCANA, 
  FILTERS_MAGIC, 
  FILTERS_POKEMON, 
  FILTERS_STARWARS, 
  FV_KEY_AGGREGATIONS,
  FV_KEY_CONDITION, 
  FV_KEY_FINISH, 
  FV_KEY_IS_SEALED, 
  FV_KEY_IS_BUYLIST,
  FV_KEY_LANGUAGE, 
  FV_KEY_NAME, 
  FV_KEY_PRODUCT_LINE, 
} from '../../constants/filters';
import { 
  FINISHES_LORCANA, 
  FINISHES_MAGIC, 
  FINISHES_POKEMON, 
  FINISHES_STARWARS, 
} from '../../constants/finishes';
import { GALLERY_CONFIG_PRODUCT_SEARCH_GENERAL } from '../../constants/gallery';
import { 
  ICON_CHEVRON_LEFT_DOUBLE,
  ICON_CHEVRON_RIGHT_DOUBLE,
  ICON_FILTER,
} from '../../constants/icons';
import {
  LANG_PRODUCT_GENERIC,
  LANG_PRODUCT_LORCANA, 
  LANG_PRODUCT_MAGIC, 
  LANG_PRODUCT_POKEMON,
  LANG_PRODUCT_STARWARS,
} from '../../constants/languages';
import { MKT_NOTICE_KEY_HEADER } from '../../constants/marketing';
import * as tx from '../../constants/strings';
import {
  PL_PERMALINK_LORCANA, 
  PL_PERMALINK_MAGIC, 
  PL_PERMALINK_POKEMON, 
  PL_PERMALINK_STARWARS, 
} from '../../constants/product';
import {
  URL_BUY_GENERAL,
  URL_BUY_PRODUCT_LINE, 
  URL_SELL_GENERAL,
  URL_SELL_PRODUCT_LINE, 
} from '../../constants/urls';


import { viewItemListEvent } from '../../utils/analytics';
import {
  getFilterConfigByKey, 
  getFilterConfigKeys, 
  getGeneralFilters, 
  getStaticFilterOptions, 
  hasStaticFilterOptions, 
  normalizeFilterValuesConditions,
  normalizeFilterValuesFinish, 
  normalizeFilterValuesLanguages, 
  normalizeFilterValuesProductLines, 
} from '../../utils/filters';
import { 
  formatServerError, 
  stringFormat, 
} from '../../utils/formatting';
import {
  getGalleryDefaultPageSize, 
  getGalleryFilterStorageKey, 
} from '../../utils/gallery';
import {
  isVarArray, 
  isVarNumber, 
  isVarString, 
} from '../../utils/general';
import { 
  getPageLimit, 
  getPageOffset, 
} from '../../utils/request';
import {
  getOrderedProductLines, 
} from '../../utils/product';

import Gallery from '../Gallery/Gallery';
import HeaderSearch from '../Header/elements/HeaderSearch';
import Icon from '../Icons/Icon';
import ProductFilters from './blocks/ProductFilters';

import './style/_productsearch.scss';

import * as productActionCreators from '../../actions/product';
import * as productLineActionCreators from '../../actions/product-line';
let allActionCreators = Object.assign({}, productActionCreators, productLineActionCreators);



export class ProductSearch extends Component {

  constructor(props) {
    super(props);

    this.primaryFilterKey = getGalleryFilterStorageKey(GALLERY_CONFIG_PRODUCT_SEARCH_GENERAL);

    this.isBuylist = this.props.isBuylist ? true : false;

    this.pageFilters = this.props.location.state && this.props.location.state.filters ? this.props.location.state.filters : {};

    this.buylistFilters = { 
      [FV_KEY_IS_BUYLIST]: this.isBuylist,
    };

    this.initState = {

      filters: this.getInitialFilters(),
      filtersCustomOptions: this.primaryFilterKey && this.props.product && this.props.product.filtersCustomOptions[this.primaryFilterKey] ? this.props.product.filtersCustomOptions[this.primaryFilterKey] : {},

      filtersRetracted: false, 
      filtersMobileHidden: true, 

      filtersMobileOpen: false, 
      filtersDesktopOpen: true,

      requestCount: 0,
      requestData: [],
      requestError: null,
      requestPending: false, 

      infiniteLoading: false,

      gallerySignal: 0,

      transitionHappening: false,

      noticeToggle: 0,
    }
    this.state = this.initState;

    this.filterInterval = null;

    this.filtersDesktop = React.createRef();
    this.filtersMobile = React.createRef();

    this.controllerSearch = null;

    this.checkFilters = this.checkSlidingFilters.bind(this);
    this.signalGallery = this.signalGallery.bind(this);
  }

  componentDidMount() {

    this.props.productLineFetchEnabled()
    .catch((errResp) => {
      if(errResp) { console.error(errResp); }
    });

    // Clear location state, if present
    if(this.props.location.state && this.props.location.state.filters) {
      history.replace(window.location.pathname, {});
    }

    // Call a search with initial filters
    this.callApi(this.pruneFilters(this.state.filters), 1, getGalleryDefaultPageSize(GALLERY_CONFIG_PRODUCT_SEARCH_GENERAL));

    this.filterInterval = setInterval(this.checkFilters, 50);
  }

  componentDidUpdate(prevProps, prevState) {

    // After loading product lines, check that the route is valid
    if(prevProps.productLine.enabled.length === 0 && this.props.productLine.enabled.length && this.props.match.params.productLinePermalink) {

      const permalinks = [];
      for(const pl of this.props.productLine.enabled) {
        if(this.isBuylist && pl.hasBuylist !== true) {
          continue;
        }
        permalinks.push(pl.permalink);
      }
      if(!permalinks.includes(this.props.match.params.productLinePermalink)) {
        history.replace(this.isBuylist ? URL_SELL_GENERAL : URL_BUY_GENERAL);
      }
    }

    // Filter updates
    // Use this to prevent multiple calls to updateFilters
    let updateData = null;

    // Listen for route changes
    if(prevProps.match.params.productLinePermalink !== this.props.match.params.productLinePermalink) {

      if(this.props.match.params.productLinePermalink && this.state.filters[FV_KEY_PRODUCT_LINE] !== this.props.match.params.productLinePermalink) {
        updateData = {
          [FV_KEY_PRODUCT_LINE]: this.props.match.params.productLinePermalink,
        };
      } else if(!this.props.match.params.productLinePermalink && this.state.filters[FV_KEY_PRODUCT_LINE]) {
        updateData = {
          [FV_KEY_PRODUCT_LINE]: '',
        };
      }
    }

    // Listen for location state filter changes
    if(this.props.location.state && this.props.location.state.filters && !_.isEqual(this.props.location.state, prevProps.location.state)) {
      
      updateData = updateData ? Object.assign({}, updateData, this.props.location.state.filters) : this.props.location.state.filters;

      // Remove location state
      history.replace(window.location.pathname, {});
    }

    if(updateData) {
      this.updateFilters(updateData);
    }

    if(prevProps.store.noticesDismissed.length !== this.props.store.noticesDismissed.length) {
      this.setState({ noticeToggle: Date.now() })
    }
  }

  componentWillUnmount() {

    // Clear intervals
    if(this.filterInterval) {
      clearInterval(this.filterInterval);
    }

    // Abort pending queries
    if(this.controllerSearch) {
      this.controllerSearch.abort();
    }

    // Clear name search
    this.props.productSetNameValue('');

    // Check for transition listeners
    if(this.filtersDesktop && this.filtersDesktop.current) {
      this.filtersDesktop.current.removeEventListener('transitionend', this.signalGallery, false);
    }

    // Enable body scroll
    document.body.style.overflow = 'visible';
  }

  getInitialFilters() {

    // Adjust page filters based on URL
    if(this.props.match.params.productLinePermalink) {

      if(this.props.productLine.enabled && this.props.productLine.enabled.length) {
        for(const pl of this.props.productLine.enabled) {
          if(pl.permalink === this.props.match.params.productLinePermalink) {
            if(this.isBuylist && pl.hasBuylist !== true) {
              continue;
            }
            this.pageFilters[FV_KEY_PRODUCT_LINE] = this.props.match.params.productLinePermalink;
            break;
          }
        }
        if(!this.pageFilters[FV_KEY_PRODUCT_LINE]) {
          history.replace(this.isBuylist ? URL_SELL_GENERAL : URL_BUY_GENERAL);
        }
      } else {
        // If product lines not loaded, assume correct and check again when API returns
        this.pageFilters[FV_KEY_PRODUCT_LINE] = this.props.match.params.productLinePermalink;
      }
    }

    // If this is set to true, clear existing filters (excepting product line)
    const ignoreExisting = this.props.location.state && this.props.location.state.clearFilters 
      ? this.props.location.state.clearFilters 
      : false;

    // Check any existing filters
    const existingFilters = !ignoreExisting && this.primaryFilterKey && this.props.product.filters[this.primaryFilterKey] 
      ? this.props.product.filters[this.primaryFilterKey] 
      : this.filterInitialSelections();
    
    const prundedFilters = this.state && existingFilters[FV_KEY_PRODUCT_LINE] 
      ? this.pruneFilters(existingFilters) 
      : existingFilters;

    return Object.assign({}, prundedFilters, this.pageFilters, this.buylistFilters);
  }

  filterInitialSelections() {
    // Loop through filters to be displayed and extract initSelection data

    const initSelections = {};
    const filterConfigs = this.getFilterConfig();

    for(const fc of filterConfigs) {
      if(fc.initSelection) {
        initSelections[fc.key] = fc.initSelection;
      }
    }
    return initSelections;
  }

  checkSlidingFilters() {
    
    // Check desktop
    if(this.filtersDesktop && this.filtersDesktop.current) {

      try {
        const positionRect = this.filtersDesktop.current.getBoundingClientRect();
        const retracted = (positionRect.width + positionRect.x) === 0;

        if(this.state.filtersRetracted !== retracted) {
          this.setState({
            filtersRetracted: retracted,
          });
        }
      } catch(errResp) {
        console.error(errResp);
      }
    }

    // Check mobile
    if(this.filtersMobile && this.filtersMobile.current) {

      try {
        const positionRectMobile = this.filtersMobile.current.getBoundingClientRect();
        const retractedMobile = positionRectMobile.height === 0;

        if(this.state.filtersMobileHidden !== retractedMobile) {

          this.setState({
            filtersMobileHidden: retractedMobile,
          }, () => {
            if(this.state.filtersMobileHidden && this.state.filtersMobileOpen === true) {
              this.setState({
                filtersMobileOpen: false, 
              });
            }
          });
        }
      } catch(errResp) {
        console.error(errResp);
      }
    }
  }

  pruneFilters(newFilters) {
    
    const normalUpdate = Object.assign({}, this.state.filters, newFilters);
    const newConfig = this.getFilterConfig(newFilters[FV_KEY_PRODUCT_LINE]);

    const nonConfigKeys = [
      FV_KEY_NAME,
      FV_KEY_IS_BUYLIST,
    ];

    const newConfigKeys = nonConfigKeys;
    for(const filterElementConfig of newConfig) {
      
      const filterKeys = getFilterConfigKeys(filterElementConfig, { isBuylist: this.isBuylist });
      for(const key of filterKeys) {
        newConfigKeys.push(key);
      }
    }

    const prunedUpdate = {};
    for(const key in normalUpdate) {
      if(nonConfigKeys.includes(key)) {
        prunedUpdate[key] = normalUpdate[key]; 
      } else if(newConfigKeys.includes(key)) {

        const filterElementConfig = getFilterConfigByKey(key);

        if(!hasStaticFilterOptions(filterElementConfig)) {

          const options = this.getElementOptions(filterElementConfig); 
          const optionValues = [];
          for(const optionElement of options) {
            optionValues.push(optionElement.value);
          }

          const normalUpdateValue = normalUpdate[key];

          if(isVarString(normalUpdateValue)) {
            if(optionValues.includes(normalUpdateValue)) {
              prunedUpdate[key] = normalUpdateValue;
            }
          } else if(isVarArray(normalUpdateValue)) {
            
            const prunedValues = [];
            for(const normalValue of normalUpdateValue) {
              if(optionValues.includes(normalValue)) {
                prunedValues.push(normalValue);
              }
            }
            prunedUpdate[key] = prunedValues;
          }

        } else {
          prunedUpdate[key] = normalUpdate[key]; 
        }
      }
    }
    return prunedUpdate;
  }

  // This is the function passed to both filters and gallery;
  // Add search/filter logic should be handled here, and callApi just handles the API call
  // Slightly different than the gallery in AddProduct where callApi handles both
  updateFilters(filterObj, pageNum = 1, pageSize = getGalleryDefaultPageSize(GALLERY_CONFIG_PRODUCT_SEARCH_GENERAL), infiniteScrollReq = false) {

    const updatedFilters = filterObj[FV_KEY_PRODUCT_LINE] ? this.pruneFilters(filterObj) : this.pruneFilters(Object.assign({}, this.state.filters, filterObj));

    this.setState({
      filters: updatedFilters,
    }, () => {

      const currentRoute = this.props.match.params.productLinePermalink ? this.props.match.params.productLinePermalink : '';
      if(this.state.filters[FV_KEY_PRODUCT_LINE] !== currentRoute) {
        if(this.state.filters[FV_KEY_PRODUCT_LINE]) {
          history.replace(stringFormat(this.isBuylist ? URL_SELL_PRODUCT_LINE : URL_BUY_PRODUCT_LINE, {
            productLine: this.state.filters[FV_KEY_PRODUCT_LINE],
          }));
        } else {
          history.replace(this.isBuylist ? URL_SELL_GENERAL : URL_BUY_GENERAL);
        }
      }

      if(this.primaryFilterKey) {
        this.props.productSetProductFilters({
          key: this.primaryFilterKey,
          value: this.state.filters,
        });
      }

      this.callApi(this.state.filters, pageNum, pageSize, infiniteScrollReq);
    });
  }

  removeFilter(filterKey) {

    const updatedFilters = {};
    for(const key in this.state.filters) {
      if(key !== filterKey) {
        updatedFilters[key] = this.state.filters[key];
      }
    }

    this.setState({
      filters: updatedFilters,
    }, () => {

      if(filterKey === FV_KEY_PRODUCT_LINE) {
        history.replace(this.isBuylist ? URL_SELL_GENERAL : URL_BUY_GENERAL);
      }

      if(this.primaryFilterKey) {
        this.props.productSetProductFilters({
          key: this.primaryFilterKey,
          value: this.state.filters,
        });
      }

      this.callApi(this.state.filters, 1, getGalleryDefaultPageSize(GALLERY_CONFIG_PRODUCT_SEARCH_GENERAL), false);
    });
  }

  formatFiltersForReq(filterObj) {
    
    const respObj = {};

    for(const key in filterObj) {

      switch(key) {
        case FV_KEY_PRODUCT_LINE:
          
          // if empty, do not include in req
          if(filterObj[key].length === 0) {
            break;
          }

          // Product line should be array
          respObj[key] = [ filterObj[key] ];
          break;
        case FV_KEY_IS_SEALED:
          // is_sealed should be boolean
          // if more than one options selected, send nothing
          if(filterObj[key].length === 1) {
            respObj[key] = filterObj[key][0];
          }
          break;
        default:

          if(filterObj[key] === true || filterObj[key] === false) {
            // Handle booleans
            respObj[key] = filterObj[key];
          } else if(isVarNumber(filterObj[key])) {
            // Handle numeric values
            respObj[key] = filterObj[key];
          } else if(filterObj[key].length) {
            // Handles strings/arrays
            respObj[key] = filterObj[key];
          }
      }
    }

    // Add aggregation key for all filters that have said key
    const aggregations = [];
    for(const filterConf of this.getFilterConfig()) {
      if(filterConf.aggregationKey) {
        aggregations.push(filterConf.aggregationKey);
      }
    }

    if(aggregations.length > 0) {
      respObj[FV_KEY_AGGREGATIONS] = aggregations;
    }

    return respObj;
  }

  callApi(filterObj, pageNum, pageSize, infiniteScrollReq = false) {
    
    // Cancel any existing requests
    if(this.controllerSearch) {
      this.controllerSearch.abort();
    }
    const controllerSearch = new AbortController();
    this.controllerSearch = controllerSearch;

    if(infiniteScrollReq) {
      this.setState({ 
        infiniteLoading: true,
      });
    } else {
      this.setState({ 
        requestError: null,
        requestPending: true, 
      });
    }

    const paginationData = {
      limit: getPageLimit(pageNum, pageSize),
      offset: getPageOffset(pageNum, pageSize),
    }

    const reqFilterObject = Object.assign({}, this.formatFiltersForReq(filterObj), paginationData);
    this.props.productSearchInventory(reqFilterObject, controllerSearch.signal)
    .then((resp) => {

      const newData = infiniteScrollReq ? this.state.requestData.concat(resp.data) : resp.data;

      this.setState({
        requestCount: resp.count,
        requestData: newData,
        requestError: null,
        requestPending: false, 
        infiniteLoading: false,
      });

      // Fire view_item_list analytics event
      if(pageNum <= 1) {
        viewItemListEvent(newData, {
          item_list_id: 'product_gallery',
          item_list_name: 'Product gallery', 
        });
      }
    })
    .catch((errResp) => {

      if(controllerSearch.signal.aborted) { return null; }
      
      this.setState({
        requestCount: 0,
        requestData: [],
        requestError: formatServerError(errResp),
        requestPending: false, 
        infiniteLoading: false,
      });
    });
  }

  getFilterCount() {
    if(!this.state.filters) {
      return 0;
    }

    const appliedFilters = [];

    for(const conf of this.getFilterConfig()) {
      const filterKey = conf.key;
      if(this.state.filters[filterKey] && this.state.filters[filterKey].length) {
        appliedFilters.push(filterKey);
      }
    }
    return appliedFilters.length;
  }

  getFilterConfig(plValue = null) {

    // const plPermalink = !plValue ? this.state.filters[FV_KEY_PRODUCT_LINE] : plValue;
    const plPermalink = plValue || this.state ? this.state.filters[FV_KEY_PRODUCT_LINE] : null;

    if(!plPermalink) {
      return getGeneralFilters();
    }

    switch(plPermalink) {
      case PL_PERMALINK_MAGIC:
        return FILTERS_MAGIC;
      case PL_PERMALINK_POKEMON:
        return FILTERS_POKEMON;
      case PL_PERMALINK_LORCANA:
        return FILTERS_LORCANA;
      case PL_PERMALINK_STARWARS:
        return FILTERS_STARWARS;
      default:
        return getGeneralFilters();
    }
  }

  getFilterOptions(plValue = null) {

    const filterConfig = this.getFilterConfig(plValue);
    const filterOptions = [];

    for(const filterElementConfig of filterConfig) {

      const filterKey = filterElementConfig.key;

      if(hasStaticFilterOptions(filterElementConfig)) {
        filterOptions[filterKey] = getStaticFilterOptions(filterElementConfig); 
      } else {
        filterOptions[filterKey] = this.getElementOptions(filterElementConfig, plValue); 
      }
    }

    return filterOptions;
  }

  getLanguageOptions(plValue = null) {

    const plPermalink = !plValue ? this.state.filters[FV_KEY_PRODUCT_LINE] : plValue;
    
    if(!plPermalink) {
      return normalizeFilterValuesLanguages(LANG_PRODUCT_GENERIC);
    }

    switch(plPermalink) {
      case PL_PERMALINK_MAGIC:
        return normalizeFilterValuesLanguages(LANG_PRODUCT_MAGIC);
      case PL_PERMALINK_POKEMON:
        return normalizeFilterValuesLanguages(LANG_PRODUCT_POKEMON);
      case PL_PERMALINK_LORCANA:
        return normalizeFilterValuesLanguages(LANG_PRODUCT_LORCANA);
      case PL_PERMALINK_STARWARS:
        return normalizeFilterValuesLanguages(LANG_PRODUCT_STARWARS);
      default:
        return normalizeFilterValuesLanguages(LANG_PRODUCT_GENERIC);
    }
  }

  getConditionOptions(plValue = null) {

    const plPermalink = !plValue ? this.state.filters[FV_KEY_PRODUCT_LINE] : plValue;
    
    if(!plPermalink) {
      return normalizeFilterValuesConditions(CONDITIONS_PRODUCT_GENERIC, this.isBuylist);
    }

    switch(plPermalink) {
      case PL_PERMALINK_MAGIC:
        return normalizeFilterValuesConditions(CONDITIONS_PRODUCT_TCG, this.isBuylist);
      case PL_PERMALINK_POKEMON:
        return normalizeFilterValuesConditions(CONDITIONS_PRODUCT_TCG, this.isBuylist);
      case PL_PERMALINK_LORCANA:
        return normalizeFilterValuesConditions(CONDITIONS_PRODUCT_TCG, this.isBuylist);
      case PL_PERMALINK_STARWARS:
        return normalizeFilterValuesConditions(CONDITIONS_PRODUCT_TCG, this.isBuylist);
      default:
        return normalizeFilterValuesConditions(CONDITIONS_PRODUCT_GENERIC, this.isBuylist);
    }
  }

  getFinishOptions(plValue = null) {
    
    const plPermalink = !plValue ? this.state.filters[FV_KEY_PRODUCT_LINE] : plValue;
    
    if(!plPermalink) {
      return [];
    }

    switch(plPermalink) {
      case PL_PERMALINK_MAGIC:
        return normalizeFilterValuesFinish(FINISHES_MAGIC);
      case PL_PERMALINK_POKEMON:
        return normalizeFilterValuesFinish(FINISHES_POKEMON);
      case PL_PERMALINK_LORCANA:
        return normalizeFilterValuesFinish(FINISHES_LORCANA);
      case PL_PERMALINK_STARWARS:
        return normalizeFilterValuesFinish(FINISHES_STARWARS);
      default:
        return [];
    }
  }

  getProductLineOptions() {
    const productLines = this.props.productLine.enabled;
    if(!productLines) {
      return [];
    }
    return normalizeFilterValuesProductLines(getOrderedProductLines(productLines))
  }

  getElementOptions(filterElementConfig, plValue = null) {

    const filterKey = filterElementConfig.key;

    if(filterElementConfig.dynamicOptions) {
      if(Object.keys(this.state.filtersCustomOptions).includes(filterElementConfig.key)) {
        const transformedOptions = [];
        for(const cust of this.state.filtersCustomOptions[filterElementConfig.key]) {
          if(cust.toOption) {
            transformedOptions.push(cust.toOption());
          }
        }
        return transformedOptions;
      } else {
        return [];
      }
    }

    switch(filterKey) {
      case FV_KEY_CONDITION:
        return this.getConditionOptions(plValue);
      case FV_KEY_FINISH:
        return this.getFinishOptions(plValue);
      case FV_KEY_LANGUAGE:
        return this.getLanguageOptions(plValue);
      case FV_KEY_PRODUCT_LINE:
        return this.getProductLineOptions();
      default:
        return [];
    }
  }

  getAppliedLabel(count) {
    if(count === 0) {
      return tx.TX_FILTER_APPLIED_FILTERS_ZERO;
    }
    if(count === 1) {
      return tx.TX_FILTER_APPLIED_FILTERS_SINGLE;
    } else {
      return tx.TX_FILTER_APPLIED_FILTERS_PLURAL;
    }
  }

  toggleMobileFilters() {

    if(Date.now() < (CSS_SLIDE_DURATION + this.state.filtersMobileLastToggle)) {
      return null;
    }

    this.setState({
      filtersMobileOpen: !this.state.filtersMobileOpen,
      filtersMobileLastToggle: Date.now(), 
    }, () => {

      if(this.state.filtersMobileOpen) {
        // Disable body scroll
        document.body.style.overflow = 'hidden';
      } else {
        // Enable body scroll
        document.body.style.overflow = 'visible';
      }
    })
  }

  signalGallery() {
    this.setState({ 
      gallerySignal: Date.now(), 
      transitionHappening: false,
    }, () => {
      if(this.filtersDesktop && this.filtersDesktop.current) {
        this.filtersDesktop.current.removeEventListener('transitionend', this.signalGallery, false);
      }
    });
  }

  toggleDesktopFilters() {
    if(this.filtersDesktop && this.filtersDesktop.current) {
      this.filtersDesktop.current.addEventListener('transitionend', this.signalGallery, false);
    }
    this.setState({ 
      filtersDesktopOpen: !this.state.filtersDesktopOpen, 
      transitionHappening: true,
    }, () => {
      if(this.state.filtersDesktopOpen) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    });
  }

  getToggleStyle() {
    if(this.state.filtersDesktopOpen || this.state.transitionHappening) { return {}; }

    const ARROW_MARGIN = 10;

    const headerOffset = this.headerNoticeOpen() ? CSS_HEADER_HEIGHT + CSS_HEADER_NOTICE_HEIGHT : CSS_HEADER_HEIGHT;

    return { 
      position: 'fixed',
      right: 'unset',
      left: `${ARROW_MARGIN}px`,
      top: `${headerOffset + ARROW_MARGIN}px` 
    };
  }

  setCustomOptions(optionObj) {
    try {
      const filtersOptionsClone = Object.assign({}, this.state.filtersCustomOptions);
      this.setState({
        filtersCustomOptions: Object.assign({}, filtersOptionsClone, optionObj),
      }, () => {
        if(this.primaryFilterKey) {
          this.props.productSetProductFilterCustomOptions({
            key: this.primaryFilterKey,
            value: this.state.filtersCustomOptions,
          });
        }
      });
    } catch(err) {
      console.error(err);
    }
  }

  selectThumbnail() {
    // delete this
  }

  headerNoticeOpen() {
    if(this.props.store.noticesDismissed.includes(MKT_NOTICE_KEY_HEADER)) { return null; }
    const allNotices = this.props.store.notices;
    for(const nt of allNotices) {
      if(nt.position === MKT_NOTICE_KEY_HEADER) {
        return true;
      }
    }
    return false;
  }

  render() {

    const {t} = this.props;
    const filterCount = this.getFilterCount();

    return <div className={`ProductSearch ${this.headerNoticeOpen() ? 'notified' : ''}`}>
      <div className={`productSearchWrapper ${!this.state.filtersDesktopOpen ? 'toggled' : ''}`}>
        <div className={`psFiltersWrapper ${!this.state.filtersDesktopOpen ? 'closed' : ''}`} ref={this.filtersDesktop}>
          {this.state.filtersRetracted === false ?
            <ProductFilters
              config={this.getFilterConfig()}
              loading={!this.props.productLine.enabled}
              filters={this.state.filters}
              filterOptions={this.getFilterOptions()}
              isBuylist={this.isBuylist}
              setFilter={this.updateFilters.bind(this)}
              setCustomFilterOptions={this.setCustomOptions.bind(this)}
              removeFilter={this.removeFilter.bind(this)} /> :
            null
          }
          <div 
            className={`psFiltersToggle ${!this.state.filtersDesktopOpen ? 'closed' : ''}`} 
            onClick={this.toggleDesktopFilters.bind(this)}
            style={this.getToggleStyle()}>
            <Icon value={this.state.filtersDesktopOpen ? ICON_CHEVRON_LEFT_DOUBLE : ICON_CHEVRON_RIGHT_DOUBLE} />
          </div>
        </div>

        <div className={`psGalleryWrapper ${!this.state.filtersDesktopOpen ? 'toggled' : ''}`}>
          <div className={'psGalleryMobileFilters'} ref={this.filtersMobile}>
            <div className='psmVisible'>
              <div className='psmVisibleFilterToggle' onClick={this.toggleMobileFilters.bind(this)}>
                <div className='psmIcon'>
                  <Icon 
                    value={ICON_FILTER} 
                    iconClass='fullMenuIconWrapper' />
                </div>
                <div className='psmLabel'>{t(tx.TX_FILTERS)}</div>
              </div>
              <div className='psmVisibleApplied'>
                {filterCount > 0 ?
                  <span className='appliedNumeral'>{filterCount}</span> :
                  null
                }
                <span>&nbsp;{t(this.getAppliedLabel(filterCount), {count: filterCount})}</span>
              </div>
              <div className='psmVisibleSearch'>
                <HeaderSearch />
              </div>
            </div>
            <div className={this.state.filtersMobileOpen ? 'psmDrawer open' : 'psmDrawer'}>
              <div className='psmDrawerFilterWrapper'>
                {this.state.filtersMobileHidden === false ?
                  <ProductFilters
                    config={this.getFilterConfig()}
                    loading={!this.props.productLine.enabled}
                    filters={this.state.filters}
                    filterOptions={this.getFilterOptions()}
                    isBuylist={this.isBuylist}
                    setFilter={this.updateFilters.bind(this)}
                    setCustomFilterOptions={this.setCustomOptions.bind(this)}
                    removeFilter={this.removeFilter.bind(this)} /> :
                  null
                }
              </div>
              <div className='psmDrawerFooterWrapper'>
                <div>
                  <button type='button' className='psmFooterAction' onClick={this.toggleMobileFilters.bind(this)}>{t(tx.TX_FILTER)}</button>
                </div>
              </div>
            </div>
          </div>
          <div className='psGallery'>
            <Gallery 
              config={GALLERY_CONFIG_PRODUCT_SEARCH_GENERAL}
              callApi={this.updateFilters.bind(this)}
              data={this.state.requestData}
              dataCount={this.state.requestCount} 
              dataLoading={this.state.requestPending}
              filters={this.state.filters}
              infiniteLoading={this.state.infiniteLoading}
              isBuylist={this.isBuylist}
              selectAction={this.selectThumbnail.bind(this)}
              signal={this.state.gallerySignal} />
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    product: state.product,
    productLine: state.productLine,
    store: state.store,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductSearch));