

import * as _ from 'underscore';

import JsBarcode from 'jsbarcode';
import jsPDF from 'jspdf';

import { LANG_EN } from '../constants/languages';
import {
	ORDER_EXPORT_ADDRESS_COLUMN_WIDTH,
	ORDER_EXPORT_ADDRESS_ROW_HEIGHT,
	ORDER_EXPORT_BACKGROUND_COLOR, 
	ORDER_EXPORT_FONT_FAMILY,
	ORDER_EXPORT_FONT_SIZE, 
	ORDER_EXPORT_FOOTER_HR_HEIGHT,
	ORDER_EXPORT_FOOTER_PAYMENT_SECTION_COST_WIDTH, 
	ORDER_EXPORT_FOOTER_PAYMENT_SECTION_WIDTH,
	ORDER_EXPORT_HEADER_COLOR,
	ORDER_EXPORT_HEADER_COLOR_LIGHT,
	ORDER_EXPORT_HEADER_TEXT_COLOR, 
	ORDER_EXPORT_HEADER_FONT_SIZE, 
	ORDER_EXPORT_HEADER_HEIGHT,
	ORDER_EXPORT_PAGE_MARGIN, 
	ORDER_EXPORT_SECTION_MARGIN,
	ORDER_EXPORT_SHIPPING_ADDRESS_VISIBLE_TOP,
	ORDER_EXPORT_SHIPPING_ADDRESS_VISIBLE_LEFT,
	ORDER_EXPORT_TABLE_COLUMN_COLOR_ROW_ALT,
	ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE,
	ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL, 
	ORDER_EXPORT_TABLE_COLUMN_QUANTITY_WIDTH,
	ORDER_EXPORT_TABLE_COLUMN_NAME_WIDTH,
	ORDER_EXPORT_TABLE_COLUMN_PRICE_WIDTH,
	ORDER_EXPORT_TABLE_INVENTORY_WIDTH,
	ORDER_EXPORT_TEXT_COLOR, 

	ORDER_STATUS_CLASS_NULL,
	ORDER_STATUS_CLASS_SUFFIX_ERROR,
	ORDER_STATUS_CLASS_SUFFIX_WARNING,
	ORDER_STATUS_KEY_PENDING,
	ORDER_STATUS_KEY_PREORDER,
	ORDER_STATUS_KEY_SHIPPED,
	ORDER_STATUS_KEY_WAITING_FOR_ITEMS,

	ORDER_ITEM_GRADE_TYPE_CONFIRM,
	ORDER_ITEM_GRADE_TYPE_MODIFIED,
	ORDER_ITEM_GRADE_TYPE_REMOVED,
} from '../constants/orders';
import { STORE_LOGO_PRINT_URL } from '../constants/store';
import * as tx from '../constants/strings';

import { 
	BuylistCart,
	Cart, 
} from './carts';
import { Address } from './geographies';
import {
	PaymentMethod,
	PaymentRecord,
} from './payment-methods';
import { Coupon } from './promotions';
import {
	GoodsReceipt,
	Pickup,
	Shipment,
} from './shipments';
import {
	ShippingMethod, 
} from './shipping-methods';
import {
	GuestUser,
	User,
} from './users';

import { getConditionObjectFromServerResp } from '../utils/condition';
import { getCurrencyMinorCount } from '../utils/currency';
import { 
	dateShort, 
	formatPhone,
	formatPrice, 
	serverDateFull,
} from '../utils/formatting';
import { 
	dateGetDaysAgo,
	isVarObject,
} from '../utils/general';


export class Order {

	constructor(props) {

		if(!props) { props = {}; }

		const cartObj = props.cart || {};
		const couponObj = props.coupon || null;
		const buylistCartObj = props.buylistCart || props.buylist_cart || {};
		const billingAddressObj = props.billingAddress || props.billing_address || null;
		const shippingAddressObj = props.shippingAddress || props.shipping_address || {};
		const shippingMethodObj = props.shippingMethod || props.shipping_method || {};
		const paymentMethodObj = props.paymentMethod || props.payment_method || null;
		const paymentRecordObj = props.paymentRecord || props.payment_record || null;
		const statusObj = props.status || null;

		const messageArray = props.messages || [];
		const messageModels = [];
		for(const message of messageArray) {
			messageModels.push(new OrderMessage(message));
		}

		const shipmentsArray = props.shipments || [];
		const shipmentsModels = [];
		for(const shipment of shipmentsArray) {
			shipmentsModels.push(new Shipment(shipment));
		}

		const pickupsArray = props.pickups || [];
		const pickupsModels = [];
		for(const pickup of pickupsArray) {
			pickupsModels.push(new Pickup(pickup));
		}

		const goodsReceiptArray = props.goodsReceipts || props.goods_receipts || [];
		const goodsReceiptModels = [];
		for(const gr of goodsReceiptArray) {
			goodsReceiptModels.push(new GoodsReceipt(gr));
		}

		const gradingsArray = props.gradings || [];
		const gradingsModels = [];
		for(const grading of gradingsArray) {
			gradingsModels.push(new OrderGrading(grading));
		}
		
		const userObj = props.user || {};
		const guestUserObj = props.guestUser || props.guest_user || {};

		// Order attributes
		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.displayNumber = props.displayNumber || props.display_number || '';
		this.createDate = props.createDate || props.create_date || '';
		this.reason = props.reason || '';
		this.statusValue = props.status || '';
		this.isBuylist = !!(props.isBuylist || props.is_buylist || false);
		this.shipmentCount = props.shipmentCount || props.shipment_count || 0;

		this.totalCart = parseFloat(props.totalCart) || parseFloat(props.total_cart / getCurrencyMinorCount()) || 0;
		this.totalCoupon = parseFloat(props.totalCoupon) || parseFloat(props.total_coupon / getCurrencyMinorCount()) || 0;
		this.totalGrand = parseFloat(props.totalGrand) || parseFloat(props.total_grand / getCurrencyMinorCount()) || 0;
		this.totalRefund = parseFloat(props.totalRefund) || parseFloat(props.total_refund / getCurrencyMinorCount()) || 0;
		this.totalShipping = parseFloat(props.totalShipping) || parseFloat(props.total_shipping / getCurrencyMinorCount()) || 0;
		this.totalTax = parseFloat(props.totalTax) || parseFloat(props.total_tax / getCurrencyMinorCount()) || 0;
		
		this.totalPaid = parseFloat(props.totalPaid) || parseFloat(props.total_paid / getCurrencyMinorCount()) || 0;
		this.totalPayable = parseFloat(props.totalPayable) || parseFloat(props.total_payable / getCurrencyMinorCount()) || 0;
		this.totalStoreCredit = parseFloat(props.totalStoreCredit) || parseFloat(props.total_store_credit / getCurrencyMinorCount()) || 0;

		this.cart = new Cart(cartObj);
		this.coupon = couponObj ? new Coupon(couponObj) : null;
		this.buylistCart = new BuylistCart(buylistCartObj);
		this.date = new Date(this.createDate);
		this.billingAddress = billingAddressObj ? new Address(billingAddressObj) : null;
		this.shippingAddress = new Address(shippingAddressObj);
		this.shippingMethod = new ShippingMethod(shippingMethodObj);
		this.paymentMethod = paymentMethodObj ? new PaymentMethod(paymentMethodObj) : null;
		this.paymentRecord = paymentRecordObj ? new PaymentRecord(paymentRecordObj) : null;
		this.status = statusObj ? new OrderStatus(statusObj) : null;

		this.taxRateSales = parseFloat(props.taxRateSales) || parseFloat(props.tax_rate_sales) || 0;
		
		this.orderUser = _.isEmpty(userObj) === false ? new User(userObj) : new GuestUser(guestUserObj);
		this.orderCart = _.isEmpty(cartObj) === false ? new Cart(cartObj) : new BuylistCart(buylistCartObj);

		this.pickups = pickupsModels;
		this.shipments = shipmentsModels;
		this.messages = messageModels;
		this.goodsReceipts = goodsReceiptModels;
		this.gradings = gradingsModels;
	}

	static get initialStatus() {
		return ORDER_STATUS_KEY_PENDING;
	}

	static get preorderStatus() {
		return ORDER_STATUS_KEY_PREORDER;
	}

	static get initialBuylistStatus() {
		return ORDER_STATUS_KEY_WAITING_FOR_ITEMS;
	}

	get user() {
		return this.orderUser;
	}

	get refunds() {
		if(this.paymentRecord) {
			return this.paymentRecord.refunds;
		}
		return [];
	}

	get calculatedTax() {
		if(this.isBuylist) { return 0; }

		let totalTax = 0;
		if(this.taxRateSales) {
			totalTax += (this.cart.taxableTotal + this.totalShipping) * ( this.taxRateSales / 100 );
		}

		return totalTax;
	}

	getQuantityRefunded(cartItem) {
		if(!cartItem || !cartItem.id) { return 0; }

		let totalQty = 0;
		for(const rf of this.refunds) {
			totalQty += rf.getRefundQuantityByCartItem(cartItem);
		}
		return totalQty;
	}

	getRefundQuantityByCartItem(cartItem) {
		if(!cartItem || !cartItem.id) { return 0; }

		let qty = 0;
		for(const ri of this.refundItems) {
			if(ri.cartItem && ri.cartItem.id && ri.cartItem.id === cartItem.id) {
				qty += ri.quantity;
			}
		}
		return qty;
	}

	hasShippingMethod() {
		if(this.shippingMethod && this.shippingMethod.publicUuid) {
			return true;
		}
		return false;
	}

	hasShippingAddress() {
		if(this.shippingAddress && this.shippingAddress.publicUuid) {
			return true;
		}
		return false;
	}

	hasPaymentMethod() {
		if(this.paymentMethod && this.paymentMethod.publicUuid) {
			return true;
		}
		return false;
	}

	hasPaymentRecord() {
		if(this.paymentRecord && this.paymentRecord.publicUuid) {
			return true;
		}
		return false;
	}

	get requiresPayment() {
		if(this.totalPayable === 0) {
			return false;
		}
		return true;
	}

	get requiresShipping() {
		if(this.shippingMethod && this.shippingMethod.isPickup) {
			return false;
		}
		return true;
	}

	get readyForShipment() {
		if(!this.requiresShipping) { 
			return false; 
		}
		if(this.isBuylist) {
			return false;
		}
		if(this.shipments.length > 0) {
			for(const ship of this.shipments) {
				if(!ship.isCancelled) {
					return false;					
				}
			}
		}
		if(this.totalPayable) {
			return false;
		}
		return true;
	}

	get waitingForItems() {
		if(!this.isBuylist) {
			return false;
		}
		if(this.goodsReceipts.length > 0) {
			return false;
		}
		return true;
	}

	get requiresGrading() {
		if(!this.isBuylist) {
			return false;
		}
		if(this.waitingForItems) {
			return false;
		}
		if(this.gradings.length > 0) {
			for(const grading of this.gradings) {
				if(grading.isComplete) {
					return false;
				}
			}
		}
		return true;
	}

	get requiresPickup() {
		if(this.shippingMethod && this.shippingMethod.isPickup) {
			return true;
		}
		return false;
	}

	get pickupReadyToBeCreated() {
		if(!this.requiresPickup) { 
			return false; 
		}
		if(this.shippingMethod && this.shippingMethod.isPickup) {
			if(this.pickups.length === 0 && this.status.isComplete === false) {
				return true;
			}
			// TODO: check if all items have been added to pickups once we allow partial-order pickups
		}
		return false;
	}

	get readyForPickup() {
		if(!this.requiresPickup) { 
			return false; 
		}
		if(this.shippingMethod && this.shippingMethod.isPickup) {
			if(this.pickups.length > 0 && this.status.isComplete === false && this.pickups[0].isComplete === false) {
				return true;
			}
			// TODO: check if all items have been added to pickups once we allow partial-order pickups
		}
		return false;
	}

	get canCancel() {
		if(this.paymentRecord === null) {
			return true;
		}
		return false;
	}

	get canMakeBuylistPayment() {
		if(!this.isBuylist) {
			return false;
		}

		// This will eventually have to be expanded if we allow for multiple rounds of grading; right now there's just one
		const firstGrading = this.gradings && this.gradings.length > 0 ? this.gradings[0] : null;
		if(firstGrading && firstGrading.completeDate && this.paymentRecord === null) {
			return true;
		}
		return false;
	}

	get isComplete() {
		if(!this.status) {
			return false;
		}
		return !!this.status.isComplete;
	}

	getLatestShipment() {
		if(this.shipments && this.shipments.length > 0) {
			let latest = this.shipments[0];
			for(let i = 1; i < this.shipments.length; i++) {
				if(this.shipments[i].date > latest.date) {
					latest = this.shipments[i];
				}
			}
			return latest;
		}
		return new Shipment();
	}

	billingSameAsShipping() {
		try {
			if(!this.billingAddress) { return false; }
			return this.shippingAddress.publicUuid && this.billingAddress.publicUuid && this.shippingAddress.publicUuid === this.billingAddress.publicUuid;
		} catch(err) {
			console.error(err);
		}
		return false;
	}

	exportLine(t = null, lang = LANG_EN) {

		if(!t) { return null; }

		const lineData = {
			[t(tx.TX_ORDER_ORDER_NUMBER)]: this.displayNumber,
			[t(tx.TX_DATE)]: this.date.toLocaleString(lang, { dateStyle: 'medium', timeStyle: 'medium' }),
			[t(tx.TX_TYPE)]: this.isBuylist ? t(tx.TX_BUYLIST) : t(tx.TX_PURCHASE),
			[t(tx.TX_ORDER_CUSTOMER_NAME)]: this.user.fullName(lang),
			[t(tx.TX_ORDER_CUSTOMER_EMAIL)]: this.user.email,
			[t(tx.TX_SUBTOTAL)]: formatPrice(this.totalCart, { addTags: false, language: lang }),
			[t(tx.TX_TOTAL)]: formatPrice(this.totalGrand, { addTags: false, language: lang }),
			[t(tx.TX_MENU_ITEM_STORECREDIT)]: formatPrice(this.totalStoreCredit, { addTags: false, language: lang }),
			[t(tx.TX_PAYMENT_METHOD)]: this.paymentMethod ? t(this.paymentMethod.name) : '',
			[t(tx.TX_SHIPPING_METHOD)]: this.shippingMethod ? this.shippingMethod.name : '',
			[t(tx.TX_SHIPPING_ADDRESS)]: this.shippingAddress ? this.shippingAddress.formatSingleLine() : '',
			[t(tx.TX_BILLING_ADDRESS)]: this.billingAddress ? this.billingAddress.formatSingleLine() : '',
			[t(tx.TX_STATUS)]: this.status ? t(this.status.name) : '',
		};

		return lineData;
	}

	async generateInvoice(t = null, pdfDoc = null, config = {}) {

		if(!t) { return null; }

		const lang = config.language || LANG_EN;

		const doc = pdfDoc ? pdfDoc : new jsPDF('portrait', 'pt', 'letter');

		const pageWidth = doc.internal.pageSize.width;
		const pageHeight = doc.internal.pageSize.height;

		const headerPadding = (ORDER_EXPORT_HEADER_HEIGHT - ORDER_EXPORT_HEADER_FONT_SIZE)/4;
		const headerLineHeight = Math.round(1.2 * ORDER_EXPORT_FONT_SIZE);

		if(pdfDoc) {
			doc.addPage();
		}

		// console.log(pageWidth); // 612 (8.5")
		// console.log(pageHeight); // 792 (11")

	  doc.setFont(ORDER_EXPORT_FONT_FAMILY, 'normal');
	  doc.setFontSize(ORDER_EXPORT_FONT_SIZE);
	  doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);

	  let pageNum = 1;
	  doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL);
	  doc.text(t(tx.TX_ORDER_ORDER_NUMBER_DYNAMIC, { orderNum: this.displayNumber }), ORDER_EXPORT_PAGE_MARGIN, pageHeight - ORDER_EXPORT_PAGE_MARGIN, { baseline: 'top', align: 'left' });
	  doc.text(pageNum.toString(), pageWidth - ORDER_EXPORT_PAGE_MARGIN, pageHeight - ORDER_EXPORT_PAGE_MARGIN, { baseline: 'top', align: 'right' });


	  // LOGO SECTION

	  const logoSectionHeight = ORDER_EXPORT_SHIPPING_ADDRESS_VISIBLE_TOP - ORDER_EXPORT_PAGE_MARGIN - ORDER_EXPORT_HEADER_HEIGHT - ORDER_EXPORT_SECTION_MARGIN;

	  const logoHeight = logoSectionHeight - 3*ORDER_EXPORT_SECTION_MARGIN;
	  const imgProps = await doc.getImageProperties(STORE_LOGO_PRINT_URL);

    const logoWidth = (logoHeight * imgProps.width) / imgProps.height;
    const logoX = (pageWidth - logoWidth)/2;
    const logoY = ORDER_EXPORT_PAGE_MARGIN + ORDER_EXPORT_SECTION_MARGIN;

    doc.addImage(STORE_LOGO_PRINT_URL, 'PNG', logoX, logoY, logoWidth, logoHeight);

	  // SHIPPING ADDRESS SECTION

	  // The #10 envelope typically measures 4.125 x 9.5 inches.
	  // The typical window size for a #10 envelope is 1.125 x 4.5 inches, and it is located 0.5 inches from the left edge and 0.5 inches from the bottom edge of the envelope.

	  // To allow for wiggle in envenlope and address to be visible (should add padding for actual address):

    // 1/3 folded height: 264
    // Envelope height: 297
    // Vertical wiggle: 33

    // Top position when aligned top: 207
    // Top position when aligned bottom: 264 - 81 - 36 = 147 

	  // Top: 160
	  // Left: 36
	  // Width: 252
	  // Height: 60 

	  // Draw shipping address header

    const shippingAddressX = ORDER_EXPORT_PAGE_MARGIN;
    const shippingAddressY = ORDER_EXPORT_PAGE_MARGIN + logoSectionHeight;
    const shippingAddressWidth = ORDER_EXPORT_ADDRESS_COLUMN_WIDTH;
    const shippingAddressHeight = ORDER_EXPORT_HEADER_HEIGHT;

	  doc.setFillColor(ORDER_EXPORT_HEADER_COLOR);
	  doc.rect(shippingAddressX, shippingAddressY, shippingAddressWidth, shippingAddressHeight, 'F');

	  doc.setTextColor(ORDER_EXPORT_HEADER_TEXT_COLOR);
	  doc.setFontSize(ORDER_EXPORT_HEADER_FONT_SIZE);
	  doc.text(t(tx.TX_SHIPPING_ADDRESS), shippingAddressX + ORDER_EXPORT_SECTION_MARGIN/2, shippingAddressY + ORDER_EXPORT_HEADER_FONT_SIZE + headerPadding);

	  // Reset defaults
	  doc.setFontSize(ORDER_EXPORT_FONT_SIZE);
	  doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);

	  // Hard coded to allow visibility in envelope window
	  const shippingAddressPosX = ORDER_EXPORT_SHIPPING_ADDRESS_VISIBLE_LEFT + ORDER_EXPORT_SECTION_MARGIN; // 1/2" plus padding
	  const shippingAddressPosY = ORDER_EXPORT_SHIPPING_ADDRESS_VISIBLE_TOP;

	  const shippingAddressLines = this.shippingAddress.format();
    shippingAddressLines.forEach((ln, i) => {
	  	doc.text(ln, shippingAddressPosX, shippingAddressPosY + (i + 1)*headerLineHeight);
	  });


	  // ORDER SUMMARY SECTION

	  const orderSummaryX = ORDER_EXPORT_PAGE_MARGIN + shippingAddressWidth + ORDER_EXPORT_PAGE_MARGIN;
    const orderSummaryY = shippingAddressY;
    const orderSummaryWidth = pageWidth - orderSummaryX - ORDER_EXPORT_PAGE_MARGIN;
    const orderSummaryHeight = ORDER_EXPORT_HEADER_HEIGHT;

	  doc.setFillColor(ORDER_EXPORT_HEADER_COLOR);
	  doc.rect(orderSummaryX, orderSummaryY, orderSummaryWidth, orderSummaryHeight, 'F');

	  doc.setFillColor(ORDER_EXPORT_BACKGROUND_COLOR);
	  doc.triangle(orderSummaryX + orderSummaryWidth + 1, orderSummaryY - 1, orderSummaryX + orderSummaryWidth - orderSummaryHeight/2 - 1, orderSummaryY - 1, orderSummaryX + orderSummaryWidth + 1, orderSummaryY + orderSummaryHeight/2 + 1, 'F');

	  doc.setTextColor(ORDER_EXPORT_HEADER_TEXT_COLOR);
	  doc.setFontSize(ORDER_EXPORT_HEADER_FONT_SIZE);
	  doc.text(t(tx.TX_ORDER_ORDER_NUMBER_DYNAMIC, { orderNum: this.displayNumber }), orderSummaryX + ORDER_EXPORT_SECTION_MARGIN/2, orderSummaryY + ORDER_EXPORT_HEADER_FONT_SIZE + headerPadding);

	  // Reset defaults
	  doc.setFontSize(ORDER_EXPORT_FONT_SIZE);
	  doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);

	  let summaryIdx = 0;

	  doc.text(this.orderUser.fullName(lang), orderSummaryX + ORDER_EXPORT_SECTION_MARGIN/2, orderSummaryY + ORDER_EXPORT_HEADER_HEIGHT + ORDER_EXPORT_SECTION_MARGIN + summaryIdx*headerLineHeight);
	  summaryIdx++;

	  doc.text(this.orderUser.email, orderSummaryX + ORDER_EXPORT_SECTION_MARGIN/2, orderSummaryY + ORDER_EXPORT_HEADER_HEIGHT + ORDER_EXPORT_SECTION_MARGIN + summaryIdx*headerLineHeight);
	  summaryIdx++;

	  if(this.shippingAddress.phone) {
	  	doc.text(formatPhone(this.shippingAddress.phone, this.shippingAddress.country.code), orderSummaryX + ORDER_EXPORT_SECTION_MARGIN/2, orderSummaryY + ORDER_EXPORT_HEADER_HEIGHT + ORDER_EXPORT_SECTION_MARGIN + summaryIdx*headerLineHeight);
	  	summaryIdx++;
	  }

	  doc.text(serverDateFull(this.date), orderSummaryX + ORDER_EXPORT_SECTION_MARGIN/2, orderSummaryY + ORDER_EXPORT_HEADER_HEIGHT + ORDER_EXPORT_SECTION_MARGIN + summaryIdx*headerLineHeight);
	  summaryIdx++;



	  // SHIPPING METHOD SECTION
	  
	  const shippingMethodX = shippingAddressX;
    const shippingMethodY = ORDER_EXPORT_PAGE_MARGIN + logoSectionHeight + ORDER_EXPORT_ADDRESS_ROW_HEIGHT;
    const shippingMethodWidth = ORDER_EXPORT_ADDRESS_COLUMN_WIDTH;
    const shippingMethodHeight = ORDER_EXPORT_HEADER_HEIGHT;

    doc.setFillColor(ORDER_EXPORT_HEADER_COLOR);
	  doc.rect(shippingMethodX, shippingMethodY, shippingMethodWidth, shippingMethodHeight, 'F');

	  doc.setTextColor(ORDER_EXPORT_HEADER_TEXT_COLOR);
	  doc.setFontSize(ORDER_EXPORT_HEADER_FONT_SIZE);
	  doc.text(t(tx.TX_SHIPPING_METHOD), shippingMethodX + ORDER_EXPORT_SECTION_MARGIN/2, shippingMethodY + ORDER_EXPORT_HEADER_FONT_SIZE + headerPadding);

	  // Reset defaults
	  doc.setFontSize(ORDER_EXPORT_FONT_SIZE);
	  doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);

	  doc.text(this.shippingMethod.name, shippingMethodX + ORDER_EXPORT_SECTION_MARGIN/2, shippingMethodY + ORDER_EXPORT_HEADER_HEIGHT + ORDER_EXPORT_SECTION_MARGIN + 0*headerLineHeight);


	  // BARCODE SECTION

	  const canvas = document.createElement('canvas');
	  JsBarcode(canvas, this.displayNumber, {
		  format: 'CODE128',
		  displayValue: false,
		  background: ORDER_EXPORT_BACKGROUND_COLOR,
		  lineColor: ORDER_EXPORT_TEXT_COLOR,
		  width: 2,
		  height: 40,
		  margin: 0,
		});

		const barcodeImg = canvas.toDataURL('image/png', { cache: 'no-store' });
		const barcodeProps = await doc.getImageProperties(barcodeImg);

		const barcodeX = orderSummaryX;
		const barcodeY = shippingMethodY;
		const barcodeWidth = orderSummaryWidth;
		const barcodeHeight = barcodeWidth * (barcodeProps.height / barcodeProps.width);

		doc.addImage(barcodeImg, 'PNG', barcodeX, barcodeY, barcodeWidth, barcodeHeight, `bc${this.displayNumber}`, 'NONE');


		// TABLE SECTION

		const tableX = shippingAddressX;
    const tableY = barcodeY + barcodeHeight + ORDER_EXPORT_SECTION_MARGIN;
    const tableWidth = pageWidth - 2*ORDER_EXPORT_PAGE_MARGIN;
    const tableHeaderHeight = ORDER_EXPORT_HEADER_HEIGHT;

    const tableQtyWidth = (tableWidth - 2*ORDER_EXPORT_SECTION_MARGIN) * ORDER_EXPORT_TABLE_COLUMN_QUANTITY_WIDTH;
    const tableNameWidth = (tableWidth - 2*ORDER_EXPORT_SECTION_MARGIN) * ORDER_EXPORT_TABLE_COLUMN_NAME_WIDTH;
    const tablePriceWidth = (tableWidth - 2*ORDER_EXPORT_SECTION_MARGIN) * ORDER_EXPORT_TABLE_COLUMN_PRICE_WIDTH;

    let invoiceCursorY = tableY;

		// Add table header

	  doc.setFillColor(ORDER_EXPORT_HEADER_COLOR);
	  doc.rect(tableX, tableY, tableWidth, ORDER_EXPORT_HEADER_HEIGHT, 'F');

	  doc.setFillColor(ORDER_EXPORT_BACKGROUND_COLOR);
	  doc.triangle(tableX + tableWidth + 1, tableY - 1, tableX + tableWidth - tableHeaderHeight/2 - 1, tableY - 1, tableX + tableWidth + 1, tableY + tableHeaderHeight/2 + 1, 'F');

	  doc.setTextColor(ORDER_EXPORT_HEADER_TEXT_COLOR);
	  doc.setFontSize(ORDER_EXPORT_HEADER_FONT_SIZE);
	  
	  doc.text('#', tableX + ORDER_EXPORT_SECTION_MARGIN/2 + tableQtyWidth/2, tableY + tableHeaderHeight/2, { baseline: 'middle', align: 'center' });
	  doc.text(t(tx.TX_NAME).toUpperCase(), tableX + tableQtyWidth + 2*ORDER_EXPORT_SECTION_MARGIN/2, tableY + tableHeaderHeight/2, { baseline: 'middle' });
	  doc.text(t(tx.TX_PRICE).toUpperCase(), tableWidth - tablePriceWidth/2 - ORDER_EXPORT_SECTION_MARGIN/2, tableY + tableHeaderHeight/2, { baseline: 'middle', align: 'center' });

	  doc.setDrawColor(ORDER_EXPORT_BACKGROUND_COLOR);
	  doc.line(tableX + tableQtyWidth + ORDER_EXPORT_SECTION_MARGIN/2, tableY + ORDER_EXPORT_SECTION_MARGIN/4, tableX + tableQtyWidth + ORDER_EXPORT_SECTION_MARGIN/2, tableY + tableHeaderHeight - ORDER_EXPORT_SECTION_MARGIN/4);
	  doc.line(tableX + tableQtyWidth + tableNameWidth + 2*ORDER_EXPORT_SECTION_MARGIN/2, tableY + ORDER_EXPORT_SECTION_MARGIN/4, tableX + tableQtyWidth + tableNameWidth + 2*ORDER_EXPORT_SECTION_MARGIN/2, tableY + tableHeaderHeight - ORDER_EXPORT_SECTION_MARGIN/4);

	  // Reset default font color
	  doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);

	  invoiceCursorY += tableHeaderHeight;

	  const tableFontHeight = doc.getLineHeightFactor() * ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE;
	  const tableSmallFontHeight = doc.getLineHeightFactor() * ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;

	  this.orderCart.getOrderedItems().forEach((cartItem, i) => {
	  	
	  	// Get inventory configs
	  	const invConfigs = [];

	  	if(cartItem.shouldDisplayLanguage()) {
	  		invConfigs.push({
	  			label: `${t(tx.TX_LANGUAGE)}: `,
	  			value: cartItem.inventory.language ? t(cartItem.inventory.language.nameTranslation) : '',
	  		});
	  	}

	  	if(cartItem.shouldDisplayCondition()) {

	  		const conditionElement = {
	  			label: `${t(tx.TX_CONDITION)}: `,
	  			value: cartItem.inventory.condition ? cartItem.inventory.condition.value : '',
	  		}

	  		if(cartItem.inventory.condition && cartItem.inventory.condition.fontColor) {
	  			conditionElement['fontColor'] = cartItem.inventory.condition.fontColor;
	  		}

	  		invConfigs.push(conditionElement);
	  	}

	  	if(cartItem.shouldDisplayFinish()) {
	  		invConfigs.push({
	  			label: `${t(tx.TX_FILTER_FINISH)}: `,
	  			value: cartItem.inventory.finish ? t(cartItem.inventory.finish.name) : '',
	  		});
	  	}
	  	
	  	if(cartItem.shouldDisplayPrinting()) {
	  		invConfigs.push({
	  			label: `${t(tx.TX_PRINTING)}: `,
	  			value: cartItem.inventory.printing ? t(cartItem.inventory.printing.name) : '',
	  		});
	  	}

	  	// Define values for name, subtitle (set/collector number), and price
	  	const nameCollectorNumberValue = cartItem.product.foreignModel && cartItem.product.foreignModel.collectorNumber ? ` (${cartItem.product.foreignModel.collectorNumber})` : '';
	  	const nameSetNameValue = cartItem.product.setName ? `${cartItem.product.setName}${nameCollectorNumberValue}` : '';
	  	
	  	const nameValue = nameSetNameValue ? `${cartItem.product.nameWithTags} | ` : `${cartItem.product.nameWithTags}`;
	  	const nameStringWidth = doc.getStringUnitWidth(nameValue)*ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE;
	  	
	  	// const priceValue = this.isBuylist ? cartItem.inventory.buyPrice : cartItem.inventory.sellPrice;
	  	const priceValue = cartItem.price;
	  	const priceString = `${formatPrice(cartItem.quantity*priceValue, { language: lang })} (${cartItem.quantity} x ${formatPrice(priceValue, { language: lang })})`;


	  	// Get height of new row
	  	const setNameStartX = tableX + tableQtyWidth + 2*ORDER_EXPORT_SECTION_MARGIN/2 + nameStringWidth;
	  	const setNameAvailableWidth = tableNameWidth - nameStringWidth;

	  	const nameSetNameLines = doc.splitTextToSize(nameSetNameValue, setNameAvailableWidth, { fontSize: ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL });

	  	const setNameHeight = 0.5*(tableFontHeight - tableSmallFontHeight) + nameSetNameLines.length*tableSmallFontHeight;
	  	const rowNameHeight = Math.max(tableFontHeight, setNameHeight);

	  	const rowHeight = rowNameHeight + Math.ceil(invConfigs.length/2)*tableSmallFontHeight + ORDER_EXPORT_SECTION_MARGIN/2;
	  	

	  	// Add page if necessary
	  	if(invoiceCursorY + rowHeight + ORDER_EXPORT_HEADER_HEIGHT > pageHeight - 2*ORDER_EXPORT_PAGE_MARGIN) {
	  		doc.addPage();
	  		invoiceCursorY = ORDER_EXPORT_PAGE_MARGIN;

	  		pageNum += 1;
			  doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL);
			  doc.text(t(tx.TX_ORDER_ORDER_NUMBER_DYNAMIC, { orderNum: this.displayNumber }), ORDER_EXPORT_PAGE_MARGIN, pageHeight - ORDER_EXPORT_PAGE_MARGIN, { baseline: 'top', align: 'left' });
			  doc.text(pageNum.toString(), pageWidth - ORDER_EXPORT_PAGE_MARGIN, pageHeight - ORDER_EXPORT_PAGE_MARGIN, { baseline: 'top', align: 'right' });
	  	}

	  	if(i % 2) {
	  		// Draw alternate color background
	  		doc.setFillColor(ORDER_EXPORT_TABLE_COLUMN_COLOR_ROW_ALT);
	  		doc.rect(tableX, invoiceCursorY, tableWidth, rowHeight, 'F');
	  	}

	  	// Set primary table font size
		  doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE);

	  	// Write primary line text
		  const rowY = invoiceCursorY + tableFontHeight/2 + ORDER_EXPORT_SECTION_MARGIN/4;

		  doc.text(cartItem.quantity.toString(), tableX + ORDER_EXPORT_SECTION_MARGIN/2 + tableQtyWidth/2, rowY, { align: 'center', baseline: 'middle' });
		  doc.text(nameValue, tableX + tableQtyWidth + 2*ORDER_EXPORT_SECTION_MARGIN/2, rowY, { baseline: 'middle' });

		  if(nameSetNameValue) {

				// Use smaller font for set names
		  	doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL);
		  	for(let i = 0; i < nameSetNameLines.length; i++) {
		  		doc.text(nameSetNameLines[i], setNameStartX, rowY + i*tableSmallFontHeight, { baseline: 'middle' });
		  	}

		  	// Reset primary table font size
		  	doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE);
		  }

		  doc.text(priceString, ORDER_EXPORT_PAGE_MARGIN + tableWidth - ORDER_EXPORT_SECTION_MARGIN/2, rowY, { baseline: 'middle', align: 'right' });

		  // Set config table font size
		  doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL);

		  // Write config lines text
		  invConfigs.forEach((configLine, j) => {
		  	
		  	const labelWidth = doc.getStringUnitWidth(configLine.label)*ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL;
		  	const labelX = j%2 ? tableX + tableQtyWidth + (3/2)*ORDER_EXPORT_SECTION_MARGIN + ORDER_EXPORT_TABLE_INVENTORY_WIDTH : tableX + tableQtyWidth + (3/2)*ORDER_EXPORT_SECTION_MARGIN;
		  	const labelY = invoiceCursorY + rowNameHeight + ORDER_EXPORT_SECTION_MARGIN/4 + tableSmallFontHeight/2 + Math.floor(j/2)*tableSmallFontHeight;

		  	doc.text(configLine.label, labelX, labelY, { baseline: 'middle' });

		  	if(configLine.fontColor) {
		  		doc.setTextColor(configLine.fontColor);
		  	}

		  	doc.text(configLine.value, labelX + labelWidth, labelY, { baseline: 'middle' });

		  	doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);
		  });

		  invoiceCursorY += rowHeight;
	  });


	  // Add table footer

	  doc.setFillColor(ORDER_EXPORT_HEADER_COLOR_LIGHT);
	  doc.rect(tableX, invoiceCursorY, tableWidth, ORDER_EXPORT_HEADER_HEIGHT, 'F');

	  doc.setFillColor(ORDER_EXPORT_BACKGROUND_COLOR);
	  doc.triangle(tableX - 1, invoiceCursorY + ORDER_EXPORT_HEADER_HEIGHT + 1, tableX + ORDER_EXPORT_HEADER_HEIGHT/2 + 1, invoiceCursorY + ORDER_EXPORT_HEADER_HEIGHT + 1, tableX - 1, invoiceCursorY + ORDER_EXPORT_HEADER_HEIGHT - ORDER_EXPORT_HEADER_HEIGHT/2 - 1, 'F');

	  doc.setDrawColor(ORDER_EXPORT_BACKGROUND_COLOR);
	  doc.line(tableX + tableQtyWidth + ORDER_EXPORT_SECTION_MARGIN/2, invoiceCursorY + ORDER_EXPORT_SECTION_MARGIN/4, tableX + tableQtyWidth + ORDER_EXPORT_SECTION_MARGIN/2, invoiceCursorY + tableHeaderHeight - ORDER_EXPORT_SECTION_MARGIN/4);
	  doc.line(tableX + tableQtyWidth + tableNameWidth + 2*ORDER_EXPORT_SECTION_MARGIN/2, invoiceCursorY + ORDER_EXPORT_SECTION_MARGIN/4, tableX + tableQtyWidth + tableNameWidth + 2*ORDER_EXPORT_SECTION_MARGIN/2, invoiceCursorY + tableHeaderHeight - ORDER_EXPORT_SECTION_MARGIN/4);

	  doc.setTextColor(ORDER_EXPORT_HEADER_TEXT_COLOR);
	  doc.setFontSize(ORDER_EXPORT_HEADER_FONT_SIZE);

	  doc.text(this.orderCart.quantity.toString(), tableX + ORDER_EXPORT_SECTION_MARGIN/2 + tableQtyWidth/2, invoiceCursorY + ORDER_EXPORT_HEADER_HEIGHT/2, { baseline: 'middle', align: 'center' });
	  doc.text(formatPrice(this.totalCart, { language: lang }), ORDER_EXPORT_PAGE_MARGIN + tableWidth - ORDER_EXPORT_SECTION_MARGIN/2, invoiceCursorY + ORDER_EXPORT_HEADER_HEIGHT/2, { baseline: 'middle', align: 'right' });

	  invoiceCursorY += ORDER_EXPORT_HEADER_HEIGHT;


	  // FOOTER SECTION

	  invoiceCursorY += ORDER_EXPORT_SECTION_MARGIN;

	  const footerX = ORDER_EXPORT_PAGE_MARGIN;
	  const footerY = invoiceCursorY;
	  const footerWidth = pageWidth - 2*ORDER_EXPORT_PAGE_MARGIN;

	  const footerPaymentWidth = (footerWidth - ORDER_EXPORT_SECTION_MARGIN) * ORDER_EXPORT_FOOTER_PAYMENT_SECTION_WIDTH;

	  const footerPaymentCostWidth = footerPaymentWidth * ORDER_EXPORT_FOOTER_PAYMENT_SECTION_COST_WIDTH;

	  const footerFontSize = ORDER_EXPORT_FONT_SIZE;
	  const footerLineHeight = doc.getLineHeightFactor() * footerFontSize;

	  const footerHeaderFontSize = ORDER_EXPORT_HEADER_FONT_SIZE;
	  const footerHeaderLineHeight = doc.getLineHeightFactor() * footerHeaderFontSize;

	  // Order detail lines
	  const orderLines = [{
	  	label: t(tx.TX_SHIPPING),
	  	amount: this.totalShipping,
	  }];

	  if(this.orderCart.coupon) {
	  	orderLines.push({
	  		label: t(tx.TX_COUPON),
	  		amount: this.orderCart.couponDiscount,
	  	});
	  }

	  if(this.totalTax) {
	  	orderLines.push({
	  		label: t(tx.TX_TAX),
	  		amount: this.totalTax,
	  	});
	  }

	  const footerHeight = orderLines.length*footerLineHeight + ORDER_EXPORT_SECTION_MARGIN/2 + ORDER_EXPORT_HEADER_HEIGHT;

	  if(invoiceCursorY + footerHeight > pageHeight - 2*ORDER_EXPORT_PAGE_MARGIN) {
  		doc.addPage();
  		invoiceCursorY = ORDER_EXPORT_PAGE_MARGIN;

  		pageNum += 1;
		  doc.setFontSize(ORDER_EXPORT_TABLE_COLUMN_FONT_SIZE_SMALL);
		  doc.text(t(tx.TX_ORDER_ORDER_NUMBER_DYNAMIC, { orderNum: this.displayNumber }), ORDER_EXPORT_PAGE_MARGIN, pageHeight - ORDER_EXPORT_PAGE_MARGIN, { baseline: 'top', align: 'left' });
		  doc.text(pageNum.toString(), pageWidth - ORDER_EXPORT_PAGE_MARGIN, pageHeight - ORDER_EXPORT_PAGE_MARGIN, { baseline: 'top', align: 'right' });
  	}

	  // Payment details section
	  doc.setTextColor(ORDER_EXPORT_TEXT_COLOR);
	  doc.setFontSize(footerHeaderFontSize);
	  doc.text(t(tx.TX_PAYMENT_DETAILS), footerX, invoiceCursorY, { align: 'left', baseline: 'top' });

	  invoiceCursorY += footerHeaderLineHeight;

	  doc.setFillColor(ORDER_EXPORT_TEXT_COLOR);
	  doc.rect(footerX, invoiceCursorY, footerPaymentWidth, ORDER_EXPORT_FOOTER_HR_HEIGHT, 'F');

	  invoiceCursorY += ORDER_EXPORT_FOOTER_HR_HEIGHT + ORDER_EXPORT_SECTION_MARGIN/2;

	  const paymentLines = [];

	  if(this.paymentRecord && this.paymentRecord.totalPaid) {
	  	paymentLines.push({
	  		label: this.paymentRecord.format(t, { language: lang }),
	  		amount: this.paymentRecord.totalPaid,
	  	});
	  }

	  if(this.totalStoreCredit) {
	  	paymentLines.push({
	  		label: t(tx.TX_MENU_ITEM_STORECREDIT),
	  		amount: this.totalStoreCredit,
	  	});
	  }

	  doc.setFontSize(footerFontSize);
	  paymentLines.forEach((payLine, m) => {

	  	doc.text(formatPrice(payLine.amount, { language: lang }), footerX, invoiceCursorY, { align: 'left', baseline: 'top' });
	  	doc.text(payLine.label, footerX + footerPaymentCostWidth, invoiceCursorY, { align: 'left', baseline: 'top' });

	  	invoiceCursorY += footerLineHeight;
	  });

	  // Order summary section

	  invoiceCursorY = footerY;

	  orderLines.forEach((orderLine, n) => {

	  	doc.text(formatPrice(orderLine.amount, { language: lang }), ORDER_EXPORT_PAGE_MARGIN + footerWidth, invoiceCursorY, { align: 'right', baseline: 'top' });
	  	doc.text(orderLine.label, footerX + tableQtyWidth + tableNameWidth + 2*ORDER_EXPORT_SECTION_MARGIN/2, invoiceCursorY, { align: 'right', baseline: 'top' });

	  	invoiceCursorY += footerLineHeight;
	  });

	  invoiceCursorY += ORDER_EXPORT_SECTION_MARGIN/2;

	  // Total line

	  const totalStringWidth = doc.getStringUnitWidth(t(tx.TX_TOTAL).toUpperCase())*ORDER_EXPORT_HEADER_FONT_SIZE;

	  const totalX = footerX + tableQtyWidth + tableNameWidth + ORDER_EXPORT_SECTION_MARGIN/2 - totalStringWidth;

	  doc.setFillColor(ORDER_EXPORT_TEXT_COLOR);
	  doc.rect(totalX, invoiceCursorY, ORDER_EXPORT_PAGE_MARGIN + footerWidth - totalX, ORDER_EXPORT_HEADER_HEIGHT, 'F');

	  doc.setTextColor(ORDER_EXPORT_HEADER_TEXT_COLOR);
	  doc.setFontSize(ORDER_EXPORT_HEADER_FONT_SIZE);
	  
	  doc.text(formatPrice(this.totalGrand, { language: lang }), ORDER_EXPORT_PAGE_MARGIN + footerWidth - ORDER_EXPORT_SECTION_MARGIN/2, invoiceCursorY + ORDER_EXPORT_HEADER_HEIGHT/2, { align: 'right', baseline: 'middle' });
	  doc.text(t(tx.TX_TOTAL).toUpperCase(), footerX + tableQtyWidth + tableNameWidth + 2*ORDER_EXPORT_SECTION_MARGIN/2, invoiceCursorY + ORDER_EXPORT_HEADER_HEIGHT/2, { align: 'right', baseline: 'middle' });

	  return doc;
	}
}


export class OrderStatus {

	constructor(props) {

		if(!props) { props = {}; }

		// Order timings
		this.PENDING_DAYS_WARNING = 4;
		this.PENDING_DAYS_ERROR = 7;

		this.key = props.key || '';
		this.name = props.name || '';
		this.nameCustomer = props.nameCustomer || props.name_customer || '';
		this.actionStore = props.actionStore || props.action_store || false;
		this.actionCustomer = props.actionCustomer || props.action_customer || false;
		this.isComplete = props.isComplete || props.is_complete || false;
	}

	statusClass(date) {

		switch(this.key) {
			case ORDER_STATUS_KEY_PENDING:
				
				const daysAgo = dateGetDaysAgo(date);

				if(daysAgo >= this.PENDING_DAYS_ERROR) {
					return `${this.key}${ORDER_STATUS_CLASS_SUFFIX_ERROR}`;
				} else if(daysAgo >= this.PENDING_DAYS_WARNING) {
					return `${this.key}${ORDER_STATUS_CLASS_SUFFIX_WARNING}`;
				} else {
					return `${this.key}`;
				}
			case ORDER_STATUS_KEY_SHIPPED:
				return this.key;
			case ORDER_STATUS_KEY_WAITING_FOR_ITEMS:
				return ORDER_STATUS_CLASS_NULL;
			default:			
				return ORDER_STATUS_CLASS_NULL;
		}
	}

	getStatusMessage(order, language = LANG_EN) {

		const nullResp = {
			label: '',
			value: '',
		};

		try {

			if(!order) { return nullResp; }

			// If pending, check age
			switch(this.key) {
				case ORDER_STATUS_KEY_PENDING:
					
					const daysAgo = dateGetDaysAgo(order.date);

					if(daysAgo >= this.PENDING_DAYS_ERROR) {
						return {
							label: tx.TX_WARNING,
							value: tx.TX_ORDER_STATUS_MESSAGE_LAST_UPDATE,
							valueConfig: {
								days: daysAgo,
							},
						};
					} else if(daysAgo >= this.PENDING_DAYS_WARNING) {
						return {
							label: '',
							value: tx.TX_ORDER_STATUS_MESSAGE_LAST_UPDATE,
							valueConfig: {
								days: daysAgo,
							},
						};
					} else {
						return {
							label: '',
							value: tx.TX_ORDER_STATUS_MESSAGE_CREATED_ON,
							valueConfig: {
								dateTime: dateShort(order.date, language),
							},
						};
					}
				case ORDER_STATUS_KEY_SHIPPED:
					return {
						label: '',
						value: tx.TX_ORDER_STATUS_MESSAGE_SHIPPED,
						valueConfig: {
							dateTime: dateShort(order.getLatestShipment().date, language),
						},
					};
				case ORDER_STATUS_KEY_WAITING_FOR_ITEMS:
					return nullResp;
				default:			
					return nullResp;
			}
		} catch(err) {
			console.error(err);
			return nullResp;
		}
	}
}


export class OrderMessage {

	constructor(props) {

		if(!props) { props = {}; }

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.subject = props.subject || '';
		this.messageText = props.messageText || props.message_text || '';
		this.createDate = props.createDate || props.create_date || '';

		this.date = new Date(this.createDate);
	}

	get text() {
		return this.messageText;
	}

	getApiData() {
		const apiData = {
			message_text: this.messageText || '',
		};
		if(this.subject) {
			apiData['subject'] = this.subject || null;
		}
		return apiData;
	}
}


export class OrderGrading {

	constructor(props) {

		// As props, should accept another OrderGrading object or a server resp
		if(!props) { props = {}; }

		const initUserProps = props.initUser || props.init_user || {};
		const completeUserProps = props.completeUser || props.complete_user || {};

		this.publicUuid = props.publicUuid || props.public_uuid || '';

		this.initTime = props.initTime || props.init_time || '';
		this.initNote = props.initNote || props.init_note || '';
		this.initUser = _.isEmpty(initUserProps) === false ? new User(initUserProps) : null;
		this.initDate = this.initTime ? new Date(this.initTime) : null;

		this.completeTime = props.completeTime || props.complete_time || '';
		this.completeNote = props.completeNote || props.complete_note || '';
		this.completeUser = _.isEmpty(completeUserProps) === false ? new User(completeUserProps) : null;
		this.completeDate = this.completeTime ? new Date(this.completeTime) : null;
	}

	get isComplete() {
		return this.completeDate !== null;
	}

	getApiDataCreate() {
		const apiData = {
      // is_dropoff: this.isDropoff,
      // is_complete: this.isCompleteOrder,
      // description: this.initNote,
		};
		return apiData;
	}

	getApiDataComplete() {
		const apiData = {
			// public_uuid: this.publicUuid,
      // description: this.completeNote,
		};
		return apiData;
	}
}


export class OrderItemGrade {

	constructor(props) {

		// As props, should accept another OrderItemGrade object or a server resp
		if(!props) { props = {}; }
		
		const gradeUserProps = props.gradeUser || props.grade_user || {};

		this.publicUuid = props.publicUuid || props.public_uuid || '';

		this.gradeType = props.gradeType || props.grade_type || '';
		this.gradeNote = props.gradeNote || props.grade_note || '';
		this.gradeUser = _.isEmpty(gradeUserProps) === false ? new User(gradeUserProps) : null;

		this.quantity = parseInt(props.quantity) || 1;

		this.conditionSubmitted = isVarObject(props.conditionSubmitted || props.condition_submitted || null) ? props.condition : getConditionObjectFromServerResp(props.conditionSubmitted || props.condition_submitted || null);
		this.conditionGraded = isVarObject(props.conditionGraded || props.condition_graded || null) ? props.condition : getConditionObjectFromServerResp(props.conditionGraded || props.condition_graded || null);

		this.priceSubmitted = parseFloat(props.priceSubmitted) || parseFloat(props.price_submitted) || null;;
		this.priceGraded = parseFloat(props.priceGraded) || parseFloat(props.price_graded) || null;;
	}

	get isRemoved() {
		return this.gradeType === ORDER_ITEM_GRADE_TYPE_REMOVED;
	}

	get isModified() {
		return this.gradeType === ORDER_ITEM_GRADE_TYPE_MODIFIED;
	}

	get isConfirmed() {
		return this.gradeType === ORDER_ITEM_GRADE_TYPE_CONFIRM;
	}
}




















