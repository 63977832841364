

import { API_FOREIGN_MODEL_MAGIC_CARD } from './api';
import { 
	// FV_KEY_IS_SEALED, 
	FV_KEY_MAGIC_SET, 
} from './filters';
import { PL_PERMALINK_MAGIC } from './product';

// Using the store codes here causes a circular import...
// Haven't nailed down why, but these should sent from the backend
// And we shouldn't be hard coding exceptions
// Default layouts have been defined on the backend, but not ones custom to the store
// Specifically, the problem comes from LAYOUT_STORE_DEFAULT_OVERRIDES, which is only used on homepage, not dashboard
// Once this can be safely removed, we can delete it and this will most likely not be an issue
// import {
// 	STORE_CODE_FLYINGBEAR,
// 	STORE_CODE_OASISCHILE,
// } from './store';

import {
	TX_BLOCK_FEATURED_BUYLIST_TITLE,
	TX_BLOCK_MAGIC_SEALED_TITLE,
	TX_BLOCK_MAGIC_SINGLES_TITLE,
	TX_BLOCK_MAGIC_TOP_EDH_TITLE,
	TX_BLOCK_RECENTLY_ADDED_TITLE,
	TX_BLOCK_UPCOMING_EVENTS_TITLE,
	TX_HOME,
	TX_LAYOUT_HOME_DESCRIPTION,
	TX_LAYOUT_HOME_LABEL,
	TX_LAYOUT_HOME_NAME,
	TX_SHOP_NOW,
} from './strings';
import {
	URL_BUY_PRODUCT_LINE,
	// URL_PLAY_GENERAL,
} from './urls';


import { stringFormat } from '../utils/formatting';



// Block

export const BLOCK_TYPE_ANALYTICS_ORDER_STATUS_COUNTS = 'analytics-orders-count-by-status';
export const BLOCK_TYPE_ANALYTICS_ORDER_TOTAL = 'analytics-orders-total';
export const BLOCK_TYPE_ANALYTICS_SALES_GRAPH = 'analytics-sales-graph';
export const BLOCK_TYPE_ANALYTICS_TOP_LIST = 'analytics-top-list';
export const BLOCK_TYPE_CONTENT_BANNER = 'content-banner';
export const BLOCK_TYPE_CONTENT_IMAGE_INLINE = 'content-image-inline';
export const BLOCK_TYPE_CONTENT_IMAGE_AND_COPY = 'content-image-and-copy';
export const BLOCK_TYPE_FEATURED_BUYLIST_HORIZONTAL = 'featured-buylist-horizontal';
export const BLOCK_TYPE_FEATURED_BUYLIST_VERTICAL = 'featured-buylist-vertical';
export const BLOCK_TYPE_FEATURED_PRODUCT_LINE_VERTICAL = 'featured-product-line-vertical';
export const BLOCK_TYPE_MAGIC_SEALED_VERTICAL = 'magic-sealed-vertical';
export const BLOCK_TYPE_MAGIC_SINGLES_VERTICAL = 'magic-singles-vertical';
export const BLOCK_TYPE_MAGIC_TOP_EDH = 'magic-top-edh';
export const BLOCK_TYPE_RECENTLY_ADDED_HORIZONTAL = 'recently-added-horizontal';
export const BLOCK_TYPE_TITLE_DEFAULT = 'title-default'
export const BLOCK_TYPE_UPCOMING_EVENTS = 'upcoming-events';

export const BLOCK_CONFIG_THEME_LIGHT = 'light-0';  // If you update, also have to update style modules
export const BLOCK_CONFIG_THEME_DARK = 'dark-0';  // If you update, also have to update style modules



// Block defaults
// These are used as "empty" blocks in default layouts/configs
// We don't use these much anymore outside of homepage; eventually, we should get away from them altogether

export const BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_HORIZONTAL = {
	type: BLOCK_TYPE_FEATURED_BUYLIST_HORIZONTAL,
	config: {},
};

export const BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_VERTICAL = {
	type: BLOCK_TYPE_FEATURED_BUYLIST_VERTICAL,
	config: {},
};

export const BLOCK_DEFAULT_HOME_MAGIC_SEALED_VERTICAL = {
	type: BLOCK_TYPE_MAGIC_SEALED_VERTICAL,
	config: {},
};

export const BLOCK_DEFAULT_HOME_MAGIC_SINGLES_VERTICAL = {
	type: BLOCK_TYPE_MAGIC_SINGLES_VERTICAL,
	config: {},
};

export const BLOCK_DEFAULT_HOME_MAGIC_TOP_EDH = {
	type: BLOCK_TYPE_MAGIC_TOP_EDH,
	config: {},
};

export const BLOCK_DEFAULT_HOME_RECENTLY_ADDED = {
	type: BLOCK_TYPE_RECENTLY_ADDED_HORIZONTAL,
	config: {},
};

export const BLOCK_DEFAULT_HOME_UPCOMING_EVENTS = {
	type: BLOCK_TYPE_UPCOMING_EVENTS,
	config: {},
};

export const BLOCK_DEFAULT_FEATURED_PRODUCT_LINE_VERTICAL = {
	type: BLOCK_TYPE_FEATURED_PRODUCT_LINE_VERTICAL,
	config: {},
};

export const BLOCK_DEFAULT_CONTENT_IMAGE_INLINE = {
	type: BLOCK_TYPE_CONTENT_IMAGE_INLINE,
	config: {},
};

export const BLOCK_DEFAULT_CONTENT_HERO_BANNER = {
	type: BLOCK_TYPE_CONTENT_BANNER,
	config: {},
};


// Block configs
// The main purpose of these is to hold the default configs
// These should also be phased out eventually and defaults will live on the backend

export const BLOCK_CONFIG_ANALYTICS_SALES_GRAPH = {
	type: BLOCK_TYPE_ANALYTICS_SALES_GRAPH,
	defaultConfig: {},
};

export const BLOCK_CONFIG_ANALYTICS_TOP_LIST = {
	type: BLOCK_TYPE_ANALYTICS_TOP_LIST,
	defaultConfig: {},
};

export const BLOCK_CONFIG_FEATURED_BUYLIST_HORIZONTAL = {
	type: BLOCK_TYPE_FEATURED_BUYLIST_HORIZONTAL,
	defaultConfig: {
		title: TX_BLOCK_FEATURED_BUYLIST_TITLE,
	},
};

export const BLOCK_CONFIG_FEATURED_BUYLIST_VERTICAL = {
	type: BLOCK_TYPE_FEATURED_BUYLIST_VERTICAL,
	defaultConfig: {
		title: TX_BLOCK_FEATURED_BUYLIST_TITLE,	

		// Oasis-specific defaults
		product_line_permalink: PL_PERMALINK_MAGIC,
		foreign_model: API_FOREIGN_MODEL_MAGIC_CARD,
		aggregation_count: 2,
		aggregation_key: 'magic_formats',
		aggregation_value: 'standard,modern',
		buy_price_min: 500,
		buy_price_max: 100000,
		omit_tags: true,
		omit_promos_magic: true,
		total_qty_max: 0,
	},
};

export const BLOCK_CONFIG_MAGIC_SEALED_VERTICAL = {
	type: BLOCK_TYPE_MAGIC_SEALED_VERTICAL,
	requiredProductLine: PL_PERMALINK_MAGIC,
	defaultConfig: {
		title: TX_BLOCK_MAGIC_SEALED_TITLE,
	},
};

export const BLOCK_CONFIG_MAGIC_SINGLES_VERTICAL = {
	type: BLOCK_TYPE_MAGIC_SINGLES_VERTICAL,
	requiredProductLine: PL_PERMALINK_MAGIC,
	defaultConfig: {
		title: TX_BLOCK_MAGIC_SINGLES_TITLE,
	},
};

export const BLOCK_CONFIG_MAGIC_TOP_EDH = {
	type: BLOCK_TYPE_MAGIC_TOP_EDH,
	requiredProductLine: PL_PERMALINK_MAGIC,
	defaultConfig: {
		title: TX_BLOCK_MAGIC_TOP_EDH_TITLE,
	},
};

export const BLOCK_CONFIG_RECENTLY_ADDED = {
	type: BLOCK_TYPE_RECENTLY_ADDED_HORIZONTAL,
	defaultConfig: {
		title: TX_BLOCK_RECENTLY_ADDED_TITLE,
	},
};

export const BLOCK_CONFIG_UPCOMING_EVENTS = {
	type: BLOCK_TYPE_UPCOMING_EVENTS,
	defaultConfig: {
		title: TX_BLOCK_UPCOMING_EVENTS_TITLE,
	},
};

export const BLOCK_CONFIG_CONTENT_IMAGE_INLINE = {
	type: BLOCK_TYPE_CONTENT_IMAGE_INLINE,
	defaultConfig: {},
};

export const BLOCK_CONFIG_CONTENT_BANNER = {
	type: BLOCK_TYPE_CONTENT_BANNER,
	defaultConfig: {
		banners: [
			{
				backgroundAlt: '',
				backgroundUrl: '',
				backgroundUrlTablet: '',
				backgroundUrlMobile: '',
				linkSrc: '',
				linkFilters: {},
			},
		],
		carouselConfig: {
			autoplaySpeed: 5000,
			slideSpeed: 25,
			stopOnInteraction: false,
			loop: true,
			variableStart: false,
		},
	},
}

export const BLOCK_CONFIG_TITLE_DEFAULT = {
	type: BLOCK_TYPE_TITLE_DEFAULT,
	defaultConfig: {
		title: '',
		titleStyle: {},
		titleTranslate: false,
		subTitle: '',
		subTitleStyle: {},
		subTitleTranslate: false,
		copy: '',
		copyStyle: {},
		copyTranslate: false,
	},
};

export const BLOCK_CONFIG_CONTENT_IMAGE_AND_COPY = {
	type: BLOCK_TYPE_CONTENT_IMAGE_AND_COPY,
	defaultConfig: {
		reverse: false,
		backgroundColor: '',
		image: {
			alt: '',
			altTranslate: false,
			src: '',
		},
		content: {
			title: '',
			titleStyle: {},
			titleTranslate: false,
			copy: [],
			copyStyle: {},
			copyTranslate: false,
			actionLabel: '',
			actionLabelTranslate: false,
			actionUrl: '',
			actionUrlData: {},
		}
	},
};

export const BLOCK_CONFIG_ALL = [
	BLOCK_CONFIG_ANALYTICS_SALES_GRAPH,
	BLOCK_CONFIG_CONTENT_BANNER,
	BLOCK_CONFIG_CONTENT_IMAGE_AND_COPY,
	BLOCK_CONFIG_CONTENT_IMAGE_INLINE,
	BLOCK_CONFIG_FEATURED_BUYLIST_HORIZONTAL,
	BLOCK_CONFIG_FEATURED_BUYLIST_VERTICAL,
	BLOCK_CONFIG_MAGIC_SEALED_VERTICAL,
	BLOCK_CONFIG_MAGIC_SINGLES_VERTICAL,
	BLOCK_CONFIG_MAGIC_TOP_EDH,
	BLOCK_CONFIG_RECENTLY_ADDED,
	BLOCK_CONFIG_TITLE_DEFAULT,
	BLOCK_CONFIG_UPCOMING_EVENTS,
];




// Section

export const SECTION_TYPE_FULL_WIDTH_VERTICAL = 'full-width-vertical'; // Vertical component spanning full width
export const SECTION_TYPE_HORIZONTAL_BLOCKS = 'horizontal-blocks';

export const SECTION_DISPLAY_LOCATION_MAIN = 'main';
export const SECTION_DISPLAY_LOCATION_SIDEBAR = 'sidebar';

export const SECTION_CONFIG_KEY_COUNT_PER_ROW = 'count_per_row';

export const SECTION_ANIMATION_SLIDE_UP_BLOCKS = 'slide-up-blocks';

export const SECTION_EFFECT_BACKGROUND_CONTRAST = 'background-contrast';
export const SECTION_EFFECT_BLOCK_MARGIN_LARGE = 'block-margin-large';
export const SECTION_EFFECT_BLOCK_MARGIN_NONE = 'block-margin-none';
export const SECTION_EFFECT_BLOCK_SHADOW = 'block-shadow';
export const SECTION_EFFECT_PADDED = 'padded';


export const SECTION_DEFAULT_HOME_TOP = {
	type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
	effects: [
		SECTION_EFFECT_BLOCK_MARGIN_NONE,
	],
	blocks: [
		BLOCK_DEFAULT_CONTENT_HERO_BANNER,
	],
};

export const SECTION_DEFAULT_HOME_HORIZONTAL = {
	type: SECTION_TYPE_HORIZONTAL_BLOCKS,
	animation: SECTION_ANIMATION_SLIDE_UP_BLOCKS,
	effects: [
		SECTION_EFFECT_BACKGROUND_CONTRAST,
		SECTION_EFFECT_BLOCK_MARGIN_LARGE,
		SECTION_EFFECT_BLOCK_SHADOW,
		SECTION_EFFECT_PADDED,
	],
	style: {},
	blockStyle: {
		height: '620px',
		minWidth: '300px',
		maxWidth: '480px',
	},
	blocks: [
		BLOCK_DEFAULT_HOME_MAGIC_SINGLES_VERTICAL,
		BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_VERTICAL,
		BLOCK_DEFAULT_HOME_UPCOMING_EVENTS,
	],
};

export const SECTION_DEFAULT_HOME_BOTTOM = {
	type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
	blocks: [
		BLOCK_DEFAULT_HOME_RECENTLY_ADDED,
	],
};

export const SECTION_CONFIG_FULL_VERTICAL = {
	type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
	allowedBlocks: [],
	blockCountMin: 0,
	blockCountMax: 16,
};

export const SECTION_CONFIG_HORIZONTAL_BLOCKS = {
	type: SECTION_TYPE_HORIZONTAL_BLOCKS,
	allowedBlocks: [],
	blockCountMin: 2,
	blockCountMax: 3,
};


// Layout

// TODO: to finish a pagebuilder there will need to be a LAYOUT TYPE 

export const LAYOUT_KEY_HOME = 'home';
export const LAYOUT_KEY_DASHBOARD = 'dashboard';

export const LAYOUT_TYPE_SIDEBAR_RIGHT = 'sidebar-right';
export const LAYOUT_TYPE_VERTICAL = 'vertical';

export const LAYOUT_TYPE_CONFIG_VERTICAL = {
	type: LAYOUT_TYPE_VERTICAL,
	allowedSections: [
		SECTION_CONFIG_FULL_VERTICAL, 
		SECTION_CONFIG_HORIZONTAL_BLOCKS, 
	],
};

// Layout defaults
export const LAYOUT_DEFAULT_HOME = {
	key: LAYOUT_KEY_HOME,
	name: TX_LAYOUT_HOME_NAME,
	label: TX_LAYOUT_HOME_LABEL,
	description: TX_LAYOUT_HOME_DESCRIPTION,
	type: LAYOUT_TYPE_VERTICAL,
	sections: [
		SECTION_DEFAULT_HOME_TOP,
		SECTION_DEFAULT_HOME_HORIZONTAL,
		SECTION_DEFAULT_HOME_BOTTOM,
	],
};

// Layout instances configs
export const LAYOUT_CONFIG_HOME = {
	key: LAYOUT_KEY_HOME,
	default: LAYOUT_DEFAULT_HOME,
	allowedTypes: [
		LAYOUT_TYPE_VERTICAL,
	],
};

export const LAYOUT_CONFIGS_ALL = [
	LAYOUT_CONFIG_HOME,
];

export const LAYOUT_STORE_DEFAULT_OVERRIDES = {
	flyingbear: {
		[LAYOUT_KEY_HOME]: {
			key: LAYOUT_KEY_HOME,
			name: TX_LAYOUT_HOME_NAME,
			label: TX_LAYOUT_HOME_LABEL,
			description: TX_LAYOUT_HOME_DESCRIPTION,
			type: LAYOUT_TYPE_VERTICAL,
			sections: [
				SECTION_DEFAULT_HOME_TOP,
				{
					type: SECTION_TYPE_HORIZONTAL_BLOCKS,
					animation: SECTION_ANIMATION_SLIDE_UP_BLOCKS,
					effects: [
						SECTION_EFFECT_BACKGROUND_CONTRAST,
						SECTION_EFFECT_BLOCK_MARGIN_LARGE,
						SECTION_EFFECT_BLOCK_SHADOW,
						SECTION_EFFECT_PADDED,
					],
					blockStyle: {
						height: '620px',
						minWidth: '300px',
						maxWidth: '480px',
					},
					blocks: [
						BLOCK_DEFAULT_HOME_MAGIC_SINGLES_VERTICAL,
						BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_VERTICAL,
						BLOCK_DEFAULT_HOME_UPCOMING_EVENTS,
					],
				},
				{
					type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
					blocks: [
						BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_HORIZONTAL,
						BLOCK_DEFAULT_HOME_RECENTLY_ADDED,
					],
				},
			],
		},
	},
	oasis: {
		[LAYOUT_KEY_HOME]: {
			key: LAYOUT_KEY_HOME,
			name: TX_LAYOUT_HOME_NAME,
			label: TX_LAYOUT_HOME_LABEL,
			description: TX_LAYOUT_HOME_DESCRIPTION,
			type: LAYOUT_TYPE_VERTICAL,
			sections: [
				{
					type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
					effects: [
						SECTION_EFFECT_BLOCK_MARGIN_NONE,
					],
					blocks: [
						{
							type: BLOCK_TYPE_CONTENT_BANNER,
							config: {
								banners: [
									{	
										backgroundAlt: 'Happy Holidays',
										backgroundUrl: '/store/oasis/home/inr_preorder.jpg',
										backgroundUrlMobile: '/store/oasis/home/inr_preorder_m.jpg',
										// linkSrc: URL_PLAY_GENERAL,
										linkSrc: stringFormat(URL_BUY_PRODUCT_LINE, { productLine: PL_PERMALINK_MAGIC }),
										linkFilters: {
											// [FV_KEY_IS_SEALED]: [ 'false' ],
											[FV_KEY_MAGIC_SET]: ['inr'],
										},
									},
								],
							},
						},
					],
				},
				SECTION_DEFAULT_HOME_HORIZONTAL,
				SECTION_DEFAULT_HOME_BOTTOM,
			],
		},
	},
	oasischile: {
		[LAYOUT_KEY_HOME]: {
			key: LAYOUT_KEY_HOME,
			name: TX_LAYOUT_HOME_NAME,
			label: TX_LAYOUT_HOME_LABEL,
			description: TX_LAYOUT_HOME_DESCRIPTION,
			type: LAYOUT_TYPE_VERTICAL,
			sections: [
				SECTION_DEFAULT_HOME_TOP,
				{
					type: SECTION_TYPE_HORIZONTAL_BLOCKS,
					animation: SECTION_ANIMATION_SLIDE_UP_BLOCKS,
					effects: [
						SECTION_EFFECT_BACKGROUND_CONTRAST,
						SECTION_EFFECT_BLOCK_MARGIN_LARGE,
						SECTION_EFFECT_BLOCK_SHADOW,
						SECTION_EFFECT_PADDED,
					],
					blockStyle: {
						height: '620px',
						minWidth: '300px',
						maxWidth: '480px',
					},
					blocks: [
						BLOCK_DEFAULT_HOME_MAGIC_SINGLES_VERTICAL,
						BLOCK_DEFAULT_HOME_MAGIC_SEALED_VERTICAL,
						BLOCK_DEFAULT_HOME_UPCOMING_EVENTS,
					],
				},
				{
					type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
					blocks: [
						BLOCK_DEFAULT_HOME_FEATURED_BUYLIST_HORIZONTAL,
						BLOCK_DEFAULT_HOME_RECENTLY_ADDED,
					],
				},
			],
		},
	},
	pixelatoms: {
		[LAYOUT_KEY_HOME]: {
			key: LAYOUT_KEY_HOME,
			name: TX_LAYOUT_HOME_NAME,
			label: TX_LAYOUT_HOME_LABEL,
			description: TX_LAYOUT_HOME_DESCRIPTION,
			type: LAYOUT_TYPE_VERTICAL,
			sections: [
				{
					type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
					effects: [
						SECTION_EFFECT_BLOCK_MARGIN_NONE,
					],
					blocks: [
						{
							type: BLOCK_TYPE_CONTENT_BANNER,
							config: {
								banners: [
									{
										backgroundAlt: '',
										backgroundUrl: '/store/pixelatoms/home/banner_1.jpg',
										backgroundUrlTablet: '',
										backgroundUrlMobile: '/store/pixelatoms/home/banner_m_1.jpg',
										linkSrc: stringFormat(URL_BUY_PRODUCT_LINE, { productLine: 'built-city' }),
										linkFilters: {},
									},
									{
										backgroundAlt: '',
										backgroundUrl: '/store/pixelatoms/home/banner_2.jpg',
										backgroundUrlTablet: '',
										backgroundUrlMobile: '/store/pixelatoms/home/banner_m_2.jpg',
										linkSrc: stringFormat(URL_BUY_PRODUCT_LINE, { productLine: 'planetary-pins' }),
										linkFilters: {},
									},
								],
								carouselConfig: {
									autoplaySpeed: 8000,
									slideSpeed: 25,
									stopOnInteraction: false,
									loop: true,
									variableStart: false,
								},
							},
						},
						{
							type: BLOCK_TYPE_TITLE_DEFAULT,
							config: {
								title: 'Shop Our Projects',
								titleStyle: {},
								titleTranslate: false,
								subTitle: '',
								subTitleStyle: {},
								subTitleTranslate: false,
								copy: 'We are Dreamers, Creators, Thinkers, and Makers thriving at the intersection of design and technology to support world-changing non-profits to enable lasting impact.',
								copyStyle: {},
								copyTranslate: false,
							},
						},
						{
							type: BLOCK_TYPE_CONTENT_IMAGE_AND_COPY,
							config: {
								reverse: false,
								backgroundColor: '#393939',
								image: {
									alt: 'Planetary Pins',
									altTranslate: false,
									src: '/store/pixelatoms/home/blocks/pins.jpg',
								},
								content: {
									title: 'Out of This World Enamel',
									titleStyle: { color: '#FFFFFF' },
									titleTranslate: false,
									copy: [
										{
											tag: 'p',
											content: 'Looking for a way to keep the cosmos close while doing something stellar for our world? Our enamel planetary pins are out of this world—literally! Pin Saturn on your jacket, or keep Jupiter hanging around; they’re not just fashion, they\'re a statement. And the best part? 100% of profits support STEAM education and citizen science. So, every time someone asks about your shiny Saturn, you can proudly say, “This little beauty is funding the next generation of astronomers.” Fashion that’s fashionable and makes the world smarter? Now that’s orbit-worthy.'
										},
										{
											tag: 'p',
											className: 'creditLine',
											style: { marginTop: '20px' },
											content: 'Project Supported: {{link|https://www.spaceappschallenge.org/|NASA Space Apps Hackathon - Tampa}}'
										},
									],
									copyStyle: { color: '#FFFFFF' },
									actionLabel: TX_SHOP_NOW,
									actionLabelTranslate: true,
									actionUrl: URL_BUY_PRODUCT_LINE,
									actionUrlData: { productLine: 'planetary-pins' },
									actionUrlFilters: {},
								}
							},
						},
						{
							type: BLOCK_TYPE_CONTENT_IMAGE_AND_COPY,
							config: {
								reverse: true,
								backgroundColor: '#64B9B6',
								image: {
									alt: 'Built City',
									altTranslate: false,
									src: '/store/pixelatoms/home/blocks/built.jpg',
								},
								content: {
									title: 'Built City Prints',
									titleStyle: { color: '#FFFFFF' },
									titleTranslate: false,
									copy: [
										{
											tag: 'p',
											content: 'Built City is an illustration project with a social good mission. The project is dedicated to supporting natural heritage conservation and historic preservation by creating original illustrations of historical and beautiful places, such as monuments and national parks.   Our original illustrations are transformed into purchasable physical products such as archival art prints.  A portion of each sale goes towards supporting historic preservation and natural heritage conservation projects.  All archival art prints are made with love in the U.S.A.'
										},
										{
											tag: 'p',
											className: 'creditLine',
											style: { marginTop: '20px' },
											content: 'Project Supported: {{link|https://charlottemuseum.org/visit/exhibits-grounds/siloam/|Save Siloam School}}'
										},
									],
									copyStyle: { color: '#FFFFFF' },
									actionLabel: TX_SHOP_NOW,
									actionLabelTranslate: true,
									actionUrl: URL_BUY_PRODUCT_LINE,
									actionUrlData: { productLine: 'built-city' },
									actionUrlFilters: {},
								}
							},
						},
					],
				},
				// {
				// 	type: SECTION_TYPE_HORIZONTAL_BLOCKS,
				// 	animation: SECTION_ANIMATION_SLIDE_UP_BLOCKS,
				// 	effects: [
				// 		SECTION_EFFECT_BLOCK_MARGIN_NONE,
				// 	],
				// 	style: {
				// 		margin: '0 auto',
				// 	},
				// 	blockStyle: {},
				// 	config: {
				// 		[SECTION_CONFIG_KEY_COUNT_PER_ROW]: 2,
				// 	},
				// 	titleBlock: {
				// 		type: BLOCK_TYPE_TITLE_DEFAULT,
				// 		config: {
				// 			title: TX_SHOP_OUR_PRODUCTS,
				// 			titleStyle: {},
				// 			titleTranslate: true,
				// 			subTitle: '',
				// 			subTitleStyle: {},
				// 			subTitleTranslate: false,
				// 			copy: 'We are Dreamers, Creators, Thinkers, and Makers thriving at the intersection of design and technology inspired by the power of art and design to articulate our innovations and discoveries.',
				// 			copyStyle: '',
				// 			copyTranslate: false,
				// 		},
				// 	},
				// 	blocks: [
				// 		{
				// 			type: BLOCK_TYPE_CONTENT_IMAGE_INLINE,
				// 			config: {
				// 				theme: BLOCK_CONFIG_THEME_DARK,
				// 				title: 'Seeking the Sun',
				// 				body: '',
				// 				actionLabel: TX_SHOP,
				// 				actionUrl: URL_BUY_PRODUCT_LINE,
				// 				actionUrlData: {
				// 					productLine: 'planetary-pins',
				// 				},
				// 				backgroundUrl: 'home/blocks/sunflowers.jpg',
				// 			},
				// 		},
				// 		{
				// 			type: BLOCK_TYPE_CONTENT_IMAGE_INLINE,
				// 			config: {
				// 				theme: BLOCK_CONFIG_THEME_DARK,
				// 				title: 'Look Up',
				// 				body: '',
				// 				actionLabel: TX_SHOP,
				// 				actionUrl: URL_BUY_PRODUCT_LINE,
				// 				actionUrlData: {
				// 					productLine: 'built-city',
				// 				},
				// 				backgroundUrl: 'home/blocks/space.jpg',
				// 			},
				// 		},
				// 		{
				// 			type: BLOCK_TYPE_CONTENT_IMAGE_INLINE,
				// 			config: {
				// 				theme: BLOCK_CONFIG_THEME_DARK,
				// 				title: 'Under the Sea!',
				// 				body: '',
				// 				actionLabel: TX_SHOP,
				// 				actionUrl: URL_BUY_PRODUCT_LINE,
				// 				actionUrlData: {
				// 					productLine: 'learning-lotus',
				// 				},
				// 				backgroundUrl: 'home/blocks/ocean.jpg',
				// 			},
				// 		},
				// 		{
				// 			type: BLOCK_TYPE_CONTENT_IMAGE_INLINE,
				// 			config: {
				// 				theme: BLOCK_CONFIG_THEME_LIGHT,
				// 				title: 'Deep in the Heart of Savings',
				// 				body: '',
				// 				actionLabel: TX_CONTACT_US,
				// 				actionUrl: URL_CONTACT_US,
				// 				actionUrlData: {},
				// 				backgroundUrl: 'home/blocks/bluebonnets.jpg',
				// 			},
				// 		},
				// 	],
				// },
				{
					type: SECTION_TYPE_FULL_WIDTH_VERTICAL,
					blocks: [
						BLOCK_DEFAULT_HOME_RECENTLY_ADDED,
					],
				},
			],
		},
	},
};



// Page

export const PAGE_KEY_HOME = 'home';

export const PAGE_HOME = {
	key: PAGE_KEY_HOME,
	name: TX_HOME,
};

export const PAGES_ALL = [
	PAGE_HOME,
];
